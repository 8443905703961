export const SellingAdvantageData = [
    {
        heading: 'Attract more buyers',
        description: 'Advertise your property where buyers are searching, including Canada’s largest private sale network and Realtor.ca* simultaneously through our affiliate brokerage who is a member of the Canadian Real Estate Association'
    },
    {
        heading: 'Manage your calls & showings',
        description: "Market your property where eager buyers are actively searching – on Canada's largest private sale network and Realtor.ca* simultaneously, leveraging our affiliate brokerage, a proud member of the Canadian Real Estate Association."
    },
    {
        heading: 'Rely on experts',
        description: 'Dealing with all the calls and inquiries can become overwhelming. Let us assist you with managing inquiries and handling showing requests.'
    },
    {
        heading: 'Pay nothing upfront',
        description: 'Explore how we harness the collective expertise of our team to empower you in successfully selling privately, on your own terms. Gain greater control and visibility over every aspect of your sale process.'
    },
    {
        heading: 'Verify Buyers',
        description: "Don't waste time – focus solely on engaging with the most serious and qualified buyers. Let us assist you in uncovering their financial capacity, buying readiness, and suitability for your property."
    },
    {
        heading: 'Close your deal',
        description: 'Experience a modern and remote approach to closing your deal with expert advice and guidance, all from the comfort of your home.'
    },
    {
        heading: 'Get help setting your price',
        description: 'Selecting the right pricing strategy is crucial for your bottom line. Receive expert guidance in implementing your pricing plan, beginning with a comprehensive analysis of your local market conditions.'
    },
]

export const SellingWithUsAdvData = [
    {
        heading: 'Marketing and Promotion',
        description: 'Detail the marketing strategies employed to showcase the property to potential buyers, including online listings, social media promotion, and targeted advertising campaigns.',
        imgUrl : 'MarketingAndPromotion.png'
    },
    {
        heading: 'Agent Support and Guidance',
        description: "Emphasize the role of experienced agents who provide personalized support and guidance throughout the selling process, from pricing strategy to negotiations and closing.",
        imgUrl : 'AgentSupportAndGuidance.png'
    },
    {
        heading: 'Access to Buyer Network:',
        description: 'Highlight the extensive network of buyers and investors that the real estate agency can tap into to increase visibility and facilitate faster sales.',
        imgUrl : 'AccessBuyerNetwork.png'
    },
    {
        heading: 'Transaction Management',
        description: 'Outline the assistance offered in managing the transaction process, including handling paperwork, coordinating inspections, and facilitating communication between all parties involved.',
        imgUrl : 'TransactionMangement.png'
    },
    {
        heading: 'Transparent Pricing Structure',
        description: "Provide clarity on the pricing structure and any associated fees, ensuring transparency and avoiding surprises for sellers.",
        imgUrl : 'TrasparentPricingStructure.png'
    }
]