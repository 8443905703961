import { configureStore } from '@reduxjs/toolkit';
import loginReducer from './Slices/LoginSlice';
import filterDataSliceReducer from './Slices/FilterDataSlice';
import profileDataSliceReducer from './Slices/ProfileDataSlice';
import imageUploadSliceReducer from './Slices/ImageUploadSlice';
import userProfileDataSlice from './Slices/UserProfileData';
import adminSiteDataSlice from './Slices/AdminSiteDataSlice';
import heartDataSlice from './Slices/HeartDataSlice';
import refreshMail from './Slices/RefreshMail';
import ListingsDataSlice from './Slices/ListingsDataSlice';
import PdfDownloadSlice from './Slices/PdfDownloadSlice';
import MapBoxDataSlice from './Slices/MapBoxDataSlice';
import ActivityIconSlice from './Slices/ActivityIconSlice';
import ActStatus from './Slices/ActStatus';
import CompareListing from './Slices/CompareListing';
// import favouriteReducer from './Slices/FavouriteSlice';
// import heartCountReducer from './Slices/HeartCounterSlice';
// import checkBoxSliceReducer from './Slices/CheckBoxSlice';
// import compareModalDataSliceReducer from './Slices/CompareModalDataSlice';
// import clearDataSliceReducer from './Slices/ClearDataSlice';
// import checkBoxCounterSliceReducer from './Slices/CheckboxCounter';
// import secondCardModalSliceReducer from './Slices/SecondCardModalSlice';
// import thirdCardModalSliceReducer from './Slices/ThirdCardModalSlice';
// import clusterIdSliceReducer from "./Slices/ClusterIDSlice";
// import CallAgentSlice from './Slices/CallAgentSlice';
// import ChatFetch from './Slices/ChatFetch';
// import DeleteCheckboxes from './Slices/DeleteCheckboxes';
// import DeleteSignal from './Slices/DeleteSignal';
// import BackToMessage from './Slices/BackToMessage';


export const store = configureStore({
    reducer: {
        login: loginReducer,
        filterDataSlice: filterDataSliceReducer,
        profileDataSlice: profileDataSliceReducer,
        imageUploadSlice: imageUploadSliceReducer,
        userProfileDataSlice: userProfileDataSlice,
        adminSiteDataSlice: adminSiteDataSlice,
        heartDataSlice: heartDataSlice,
        ListingsDataSlice: ListingsDataSlice,
        PdfDownloadSlice: PdfDownloadSlice,
        MapBoxDataSlice: MapBoxDataSlice,
        refreshMail: refreshMail,
        ActivityIconSlice: ActivityIconSlice,
        ActStatus: ActStatus,
        CompareListings: CompareListing,
        // favourite: favouriteReducer,
        // heartCount: heartCountReducer,
        // selectedcardsarray: checkBoxSliceReducer,
        // compareModalDataSlice: compareModalDataSliceReducer,
        // clearDataSlice: clearDataSliceReducer,
        // checkBoxCounterSlice: checkBoxCounterSliceReducer,
        // secondModalSlice: secondCardModalSliceReducer,
        // thirdModalSlice: thirdCardModalSliceReducer,
        // clusterIdSlice: clusterIdSliceReducer,
        // CallAgent : CallAgentSlice,
        // ChatFetch:ChatFetch,
        // DeleteCheckboxes:DeleteCheckboxes,
        // DeleteSignal:DeleteSignal,
        // BackToMessage:BackToMessage,
    },
});

export default store;
