import React from "react";
import "./TermsAndConditions.css";
import Footer from "../Footer/Footer";
import HelmetLayout from "../../Utils/HelmetLayout/Helmet";

export default function TermsAndConditions() {
  return (
    <div>

      <HelmetLayout pageName={'terms-and-conditions'} canonicalLink={'terms-and-conditions'} />


      <div className="d-flex text-white container-fluid p-4 topContainer">
        <div>
          <h2 className="topHeading">Website Terms of Use</h2>
        </div>
        <div>
          <p className="topHeadingPara">
            Welcome to the ASSIGNMENTSOLD.CA website or the mobile application
            ASSIGNMENTSOLD Mobile (collectively referred to as the “Website”), owned
            and operated by ASSIGNMENTSOLD. These Terms of Use constitute a legal
            agreement between you and ASSIGNMENTSOLD regarding your access and use of
            the Website. Please read these Terms of Use carefully before
            browsing the Website or using the services it offers (referred to as
            the “Services”).
          </p>
        </div>
      </div>

      <div className="container content_contaner">
        <div>
          <h5 className="content_Heading">Acceptance of Terms of Use</h5>
          <p className="contentPara">
            By browsing the Website or using any of the Services, you agree to
            be bound by these Terms of Use. If you do not agree to these Terms
            of Use, please refrain from using the Website or the Services.
            ASSIGNMENTSOLD reserves the right to amend these Terms of Use at any
            time, at its sole discretion. Your continued use of the Website
            and/or the Services after any amendments are posted will signify
            your acceptance of the revised Terms of Use. We recommend that you
            review this page regularly.
          </p>
          <p className="contentPara">
            ASSIGNMENTSOLD also reserves the right to cancel the Terms of Use and/or
            your right to access and use all or any part of the Website or
            Services, without notice, at its sole discretion.
          </p>
        </div>

        <div>
          <h5 className="content_Heading">Advisory</h5>
          <p className="contentPara">
            The purpose of the Website is to assist users in selling or renting
            their properties (“Properties”) without involving a third party or
            paying any commission. It is the responsibility of the seller and
            buyer or the lessor and lessee to complete any potential
            transaction, understanding that ASSIGNMENTSOLD will not be a party to the
            transaction. In the interest of transparency for potential buyers
            and lessees browsing the Website, the telephone number listed and
            the lawn sign must belong to the owner or lessor, respectively. If
            the sale or rental of the Property is entrusted to a third party, it
            is not permitted to direct potential buyers or lessees to the third
            party through the listing's content (telephone number, email,
            description, etc.) or through the lawn sign, as this will result in
            the removal of the listing from the Website without a refund.
          </p>
          <p className="contentPara">
            While ASSIGNMENTSOLD makes every effort to maintain the security and
            integrity of the Website, ASSIGNMENTSOLD MAKES NO WARRANTIES OR
            REPRESENTATIONS WHATSOEVER REGARDING THE PROPERTIES, THE ACCURACY OF
            THEIR DESCRIPTIONS, OR THE ABILITY OF SELLERS OR LESSORS TO SELL OR
            RENT THEIR PROPERTIES, OR OF BUYERS OR LESSEES TO PURCHASE OR RENT
            THEM.
          </p>
          <p className="contentPara">
            ASSIGNMENTSOLD IS NOT A REAL ESTATE BROKERAGE AND DOES NOT PROVIDE ANY
            REAL ESTATE BROKERAGE SERVICES.
          </p>
          <p className="contentPara">
            From time to time, the Website may offer information on various
            aspects of selling and renting a property (“Information”). This
            Information is provided solely for educational purposes and should
            not be considered as advice, opinion, recommendation, or an action
            plan. The Website and its use are not intended to replace
            consultation with a qualified professional, where applicable.
          </p>
          <p className="contentPara">
            ASSIGNMENTSOLD authorizes only its clients with active files on the
            Website to use ASSIGNMENTSOLD signs. Failure to comply with this policy
            may result in ASSIGNMENTSOLD seizing the signs in question.
          </p>
        </div>

        <div>
          <h5 className="content_Heading">Copyright</h5>
          <p className="contentPara">
            All content, including but not limited to text, illustrations,
            graphics, images, photographs, virtual tours, information, audio or
            video clips, data, databases, maps, files, software, and codes
            available on the Website (referred to as the “Content”), as well as
            the presentation of the Content, are protected by Canadian and
            foreign laws, including copyright law, and are the property of
            ASSIGNMENTSOLD``, its affiliates, licensors, and/or the acknowledged
            Content supplier.
          </p>
          <p className="contentPara">
            In particular, DUPROPRIO holds the copyrights for all photographs
            posted on the Website and/or taken by DUPROPRIO, its employees,
            representatives, agents, and/or licensors.
          </p>
          <p className="contentPara">
            You are prohibited from: (i) copying, reproducing, modifying,
            adapting, translating, downloading, distributing, or transmitting
            all or any part of the Content; (ii) engaging in “data scraping,”
            “database scraping,” or any other activity intended to collect,
            store, reorganize, or manipulate data from the Website or any part
            of the Content in any form or by any means; (iii) selling, leasing,
            licensing, or otherwise using the Content for commercial, public, or
            non-personal purposes; (iv) modifying, removing, or covering any
            trademark or proprietary notice included in the Content; (v)
            decompiling, disassembling, decrypting, extracting, or reverse
            engineering the Website and its components, including the Content;
            or assisting anyone in doing so; (vi) using the Website in any way
            that could damage, impair, overburden, disable, or otherwise affect
            the Website or any services, accounts, servers, networks, resources
            linked or accessible through the Website.
          </p>
        </div>

        <div>
          <h5 className="content_Heading">Trademarks</h5>
          <p className="contentPara">
            ASSIGNMENTSOLD and ASSIGNMENTSOLD & DESIGN are trademarks of ASSIGNMENTSOLD or used
            under license. Other names, words, titles, phrases, logos, drawings,
            graphics, icons, and trademarks displayed on the Website may be
            trademarks of ASSIGNMENTSOLD or a third party. Nothing on the Website
            grants you any license or right to use any logo, drawing, or
            trademark of ASSIGNMENTSOLD or a third party.
          </p>
          {/* <p className="contentPara">
            *Note: Due to space constraints, the text is shortened. If you'd
            like the entire content rephrased, please let me know.*
          </p> */}
        </div>
      </div>
      <div className="mt-5">
        <Footer />
      </div>
    </div>
  );
}
