import './BuyerRequests.css'
import { RiDeleteBin6Fill } from "react-icons/ri";
import { Dropdown } from 'react-bootstrap';
import { KeyboardArrowDownSharp } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { formatPhoneNumber } from '../../CustomHook/Formatter';


const BuyerRequestCard = ({ data, dltApiCall, handleDeleteRequest, handleRequestStatusChange }) => {

    const navigate = useNavigate()
    const formatDateTime = (inputString) => {
        const dateObject = new Date(inputString);

        const day = dateObject.getDate();
        const month = dateObject.getMonth() + 1; // Month is zero-based
        const year = dateObject.getFullYear();

        const hour = dateObject.getHours();
        const minute = dateObject.getMinutes();
        const period = hour >= 12 ? 'PM' : 'AM';

        const formattedHour = hour % 12 === 0 ? 12 : hour % 12;

        const formattedString = `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year} ${formattedHour < 10 ? '0' : ''}${formattedHour}:${minute < 10 ? '0' : ''}${minute} ${period}`;

        return formattedString;
    };


    return (
        <div className="br_card">
            <button className="br-Id">{`ID: ${data?.assignmentId}`}</button>
            <h6 className='br-cardProjectname' onClick={()=>navigate(`/dashboard/buyer-request/${data?.slug}`)}>{data?.projectName}</h6>
            <div className='d-flex align-items-center mt-1'>
                <p className='br-cardPrice m-0'>{`$${data?.assignmentPrice?.toLocaleString('en-us')}`}</p>
                <p className='br-cardArea m-0 ms-2'>{`${data?.interiorSize?.toLocaleString('en-us')} sqft`}</p>
            </div>

            <div className='mt-3'>
                <p className='fw-bold m-0'>{data?.firstName}</p>
                <p className='mt-1 mb-0' style={{ color: '#000' }}>{formatPhoneNumber(data?.phone)}</p>
                <p className='mt-1 mb-0' style={{ color: '#000' }}>{data?.email}</p>
            </div>

            <div className='mt-3'>
                <p className='br-highlightText mb-0'>Owner Name: <span className='ps-2' style={{ color: '#676767' }}>{data?.ownerName}</span></p>
                <p className='br-highlightText mt-1 mb-0'>Owner Email: <span className='ps-2' style={{ color: '#676767' }}>{data?.ownerEmail}</span></p>
            </div>

            <div className='br-cardMessage mt-3'>
                <p>{data?.offer}</p>
            </div>

            <div className='mt-3'>
                <p className='br-highlightText mb-0'>Received: <span className='ps-2' style={{ color: '#676767' }}>{formatDateTime(data?.createdAt)}</span></p>
                <p className='br-highlightText mt-1 mb-0'>Last Activity: <span className='ps-2' style={{ color: '#676767' }}>{formatDateTime(data?.lastModified)}</span></p>
            </div>

            <div className='br-dltandPending mt-3'>
                <button className='br-dltBtn' disabled={dltApiCall} onClick={() => { handleDeleteRequest(data?.uId) }}>
                    Delete
                    <RiDeleteBin6Fill size={24} />
                </button>

                <Dropdown className="fs-6 br-StatusBtnP">
                    <Dropdown.Toggle className="br-requestStatusBtn" style={{ background: "#02549E" }} bsPrefix="custom-toggle">
                        {data?.status}
                        {/* {loading[listing.uId] && <div className="spinner-border spinner-border-sm ms-2" role="status"></div>} */}
                        <KeyboardArrowDownSharp />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="DropDownMenuFont" style={{ minWidth: '100%' }}>
                        {data?.status !== 'New' && (
                            <Dropdown.Item onClick={() => handleRequestStatusChange(data?.uId, "New")}>New</Dropdown.Item>
                        )}
                        {data?.status !== 'Contacted' && (
                            <Dropdown.Item onClick={() => handleRequestStatusChange(data?.uId, "Contacted")}>Contacted</Dropdown.Item>
                        )}
                        {data?.status !== 'Completed' && (
                            <Dropdown.Item onClick={() => handleRequestStatusChange(data?.uId, "Completed")}>Completed</Dropdown.Item>
                        )}
                        {data?.status !== 'DND' && (
                            <Dropdown.Item onClick={() => handleRequestStatusChange(data?.uId, "DND")}>DND</Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                </Dropdown >
            </div>
        </div>
    )
}

export default BuyerRequestCard;