import React from "react";
import "../../App.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Footer from "../Footer/Footer";

export default function Faqs() {
  return (
    <div >

      <div className="text-center  mb-5 mt-5 coloring">
        <h5 className="weightfont">FAQs</h5>
      </div>

      <div className="accor" >
        <Accordion  style={{ border: '1px solid #CCCCCC', borderRadius:'4px',boxShadow: '4px 4px 4px 4px #FFFFFF' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography sx={{ fontFamily: "Montserrat" }}>What is Assignment Sold?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ fontFamily: "Montserrat" }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <div style={{ marginTop: '10px' }}></div> {/* add space */}
        <Accordion  style={{ border: '1px solid #CCCCCC',borderRadius:'4px', boxShadow: '4px 4px 4px 4px #FFFFFF' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography sx={{ fontFamily: "Montserrat" }}>What are assignment Sales?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ fontFamily: "Montserrat" }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <div style={{ marginTop: '10px' }}></div> {/* add space */}
        <Accordion  style={{ border: '1px solid #CCCCCC', borderRadius:'4px',boxShadow: '4px 4px 4px 4px #FFFFFF' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Typography sx={{ fontFamily: "Montserrat" }}>Are Assignment Sales Legal?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ fontFamily: "Montserrat" }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <div style={{ marginTop: '10px' }}></div> {/* add space */}
        <Accordion  style={{ border: '1px solid #CCCCCC',borderRadius:'4px', boxShadow: '4px 4px 4px 4px #FFFFFF' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            <Typography sx={{ fontFamily: "Montserrat" }}>Who is Assignor?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ fontFamily: "Montserrat" }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <div style={{ marginTop: '10px' }}></div> {/* add space */}
        <Accordion  style={{ border: '1px solid #CCCCCC',borderRadius:'4px', boxShadow: '4px 4px 4px 4px #FFFFFF' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5a-content"
            id="panel5a-header"
          >
            <Typography sx={{ fontFamily: "Montserrat" }}>Who is Assignee?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ fontFamily: "Montserrat" }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <div style={{ marginTop: '10px' }}></div> {/* add space */}
        <Accordion  style={{ border: '1px solid #CCCCCC',borderRadius:'4px', boxShadow: '4px 4px 4px 4px #FFFFFF' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel6a-content"
            id="panel6a-header"
          >
            <Typography sx={{ fontFamily: "Montserrat" }}>What are benefits of the Assignor?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ fontFamily: "Montserrat" }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <div style={{ marginTop: '10px' }}></div> {/* add space */}
        <Accordion  style={{ border: '1px solid #CCCCCC',borderRadius:'4px', boxShadow: '4px 4px 4px 4px #FFFFFF' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel7a-content"
            id="panel7a-header"
          >
            <Typography sx={{ fontFamily: "Montserrat" }}>Will either the assignor or assignee's lawyer Service Required?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ fontFamily: "Montserrat" }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <div style={{ marginTop: '10px' }}></div> {/* add space */}
        <Accordion  style={{ border: '1px solid #CCCCCC',borderRadius:'4px', boxShadow: '4px 4px 4px 4px #FFFFFF' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel8a-content"
            id="panel8a-header"
          >
            <Typography sx={{ fontFamily: "Montserrat" }}>What is the process of Assignment?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ fontFamily: "Montserrat" }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <div style={{ marginBottom: '150px' }}></div> {/* add space */}
      </div>

      <div
        className="mt-5"
      >
        <Footer />
      </div>
    </div>
  );
}
