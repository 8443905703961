import { Modal } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import { TextField } from '@mui/material';
import { useFormik, Formik, Form } from "formik";
import * as Yup from 'yup';
import { useState } from 'react';


const PayModal = ({ assignmentId, setShowPayModal, selectedPackages, showPayModal, handleClosePayModal, SubmitFormData, resetForm, values, showPayLaterBtn, btnCursor, setBtnCursor }) => {

    const [isSubmited, setIsSubmited] = useState(false)

    const formInitialValues = {
        cardNumber: '',
        cardName: '',
        ExpireDate: '',
        CVCcode: '',
    }

    const PayCardSchema = Yup.object({
        cardNumber: Yup.string().min(1).max(20).required("This field is required"),
        cardName: Yup.string().min(1).max(50).required("This field is required"),
        CVCcode: Yup.string().matches(/^\d{3}$/, "CVC code must be 3 characters long").required("This field is required"),
        ExpireDate: Yup.string()
            .test('is-expired', 'Invalid Expire Date', function (value) {
                if (!value) {
                    return this.createError({ message: 'This field is required' });
                }
                const currentDate = new Date();
                const [month, year] = value.split('/').map(Number);
                if (isNaN(month) || month < 1 || month > 12) {
                    return this.createError({ message: 'Invalid month' });
                }
                if (isNaN(year) || year < 0) {
                    return this.createError({ message: 'Invalid year' });
                }
                const expireDate = new Date(year, month, 1);
                if (expireDate <= currentDate) {
                    return this.createError({ message: 'Expiration date must be in the future' });
                }
                return true;
            })
            .required("This field is required"),

    });

    const handleFormSubmit = () => {
        formik.handleSubmit();
        setIsSubmited(true);
    };

    const formik = useFormik({
        initialValues: formInitialValues,
        validationSchema: PayCardSchema,
        onSubmit: async (values, { resetForm }) => {
            console.log('Submit')
        },

    })
    const handlePayLater = async (values, resetForm) => {
        setBtnCursor(true)
        await SubmitFormData(values, resetForm)
    }

    return (
        <Modal show={showPayModal} centered onHide={handleClosePayModal} className='BumpAdd_modal' backdrop="static">
            <Modal.Body className='Bump_modal_body'>
                <div className='w-100'>
                    <Formik>
                        <Form onKeyDown={(e) => e.key === 'Enter' && handleFormSubmit()}>
                            <div className='d-flex justify-content-between'>
                                <h5 className='BumpAdd_heading'>Credit card Payment </h5>
                                {!showPayLaterBtn && (
                                    <span className='bumpAdd_closeIcon ms-3' onClick={() => {
                                        formik.resetForm();
                                        setIsSubmited(false)
                                        setShowPayModal(false);
                                    }} ><CloseIcon style={{ fontSize: '18px' }} /></span>
                                )}
                            </div>

                            <div className='d-flex flex-wrap Pay_textAll_field'>

                                <div className='Pay_text_field'>
                                    <p
                                        className="cardPay_head m-0"
                                        style={{
                                            fontFamily: "sans-serif",
                                            fontSize: "medium",
                                        }}
                                    >
                                        Card number
                                    </p>
                                    <TextField
                                        type="number"
                                        name="cardNumber"
                                        size="small"
                                        sx={{ width: "100%", borderRadius: "4px", marginBottom: "20px" }}
                                        className="cardNumPay"
                                        placeholder='Card Number'
                                        id="outlined-basic"
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: <img src={require("../../../Components/Images/PayCardImg.png")} alt='PayCard Logo' loading="lazy" className='me-2' />
                                        }}
                                        value={formik.values.cardNumber}
                                        onChange={formik.handleChange}
                                    />
                                    {isSubmited ? (
                                        <span style={{ color: "red" }}>{formik.errors.cardNumber}</span>
                                    ) : null}
                                </div>

                                <div className='Pay_text_field'>
                                    <p
                                        className="cardPay_head m-0"
                                        style={{
                                            fontFamily: "sans-serif",
                                            fontSize: "medium",
                                        }}
                                    >
                                        Card Name
                                    </p>
                                    <TextField
                                        type="text"
                                        name="cardName"
                                        size="small"
                                        placeholder='First name'
                                        sx={{ width: "100%", borderRadius: "4px", marginBottom: "20px" }}
                                        className=""
                                        id="outlined-basic"
                                        variant="outlined"
                                        value={formik.values.cardName}
                                        onChange={formik.handleChange}
                                    />
                                    {isSubmited ? (
                                        <span style={{ color: "red" }}>{formik.errors.cardName}</span>
                                    ) : null}
                                </div>


                                <div className='Pay_text_field'>
                                    <p
                                        className="cardPay_head m-0"
                                        style={{
                                            fontFamily: "sans-serif",
                                            fontSize: "medium",
                                        }}
                                    >
                                        Expire Date
                                    </p>
                                    <TextField
                                        type="text"
                                        name="ExpireDate"
                                        size="small"
                                        sx={{ width: "100%", borderRadius: "4px", marginBottom: "20px" }}
                                        placeholder='MM/YYYY'
                                        className=""
                                        id="outlined-basic"
                                        variant="outlined"
                                        value={formik.values.ExpireDate}
                                        onChange={formik.handleChange}
                                    />
                                    {isSubmited ? (
                                        <span style={{ color: "red" }}>{formik.errors.ExpireDate}</span>
                                    ) : null}
                                </div>

                                <div className='Pay_text_field'>
                                    <p
                                        className="cardPay_head m-0"
                                        style={{
                                            fontFamily: "sans-serif",
                                            fontSize: "medium",
                                        }}
                                    >
                                        CVC code
                                    </p>
                                    <TextField
                                        type="number"
                                        name="CVCcode"
                                        size="small"
                                        placeholder='Civic code'
                                        sx={{ width: "100%", borderRadius: "4px", marginBottom: "20px" }}
                                        className=""
                                        id="outlined-basic"
                                        variant="outlined"
                                        value={formik.values.CVCcode}
                                        onChange={formik.handleChange}
                                    />
                                    {isSubmited ? (
                                        <span style={{ color: "red" }}>{formik.errors.CVCcode}</span>
                                    ) : null}
                                </div>
                            </div>


                            <div className='d-flex justify-content-center align-items-center mt-4' >
                                {showPayLaterBtn && (
                                    <button type='button' disabled={btnCursor} onClick={() => handlePayLater(values, resetForm)} className={`me-3 payNow_btn creditPay_btn ${btnCursor ? 'PayLater_cursorNone' : 'cursor-pointer'}`}>Pay Later</button>
                                )}
                                <button type='button' disabled={btnCursor} onClick={handleFormSubmit} className={`payNow_btn creditPay_btn ${btnCursor ? 'PayLater_cursorNone' : 'cursor-pointer'}`}>Pay ${selectedPackages.reduce((total, pkg) => total + pkg.price, 0).toFixed(2)} now</button>
                            </div>
                        </Form>
                    </Formik>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default PayModal;