import Marquees from "./SubComponents/Marquee/Marquees";
import CallAgent from "../Components/ContactAgent/CallAgent";
import HeaderSection from "./SubComponents/HeaderSection/HeaderSection";
import CategorySearching from "./SubComponents/CategorySearching/CategorySearching";
import FullWidthTabs from "./SubComponents/Tabpanel/FullWidthTabs";
import Footer from "../Components/Footer/Footer";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import OurPromiseFromBuyer from "./SubComponents/OurPromiseForBuyer/OurPromiseForBuyer";
import LatestBlogs from "./SubComponents/LatestBlogs/LatestBlogs";
import HelmetLayout from "../Utils/HelmetLayout/Helmet";
import { ToastContainer } from "react-toastify";

const Home = () => {
  const [categoryLocation, setCategoryLocation] = useState();
  const [categoryBType, setCategoryBType] = useState();
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 800);
  useEffect(() => {
    function handleResize() {
      setIsMobileView(window.innerWidth <= 800);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <HelmetLayout pageName={"Home-page"} />

      <div style={{ position: "sticky", top: 0, zIndex: 1100 }}>
        {/* <Navbar navBarRef={navBarRef}/> */}
      </div>
      {/* <div className="container heading_logo">
        <Heading />
      </div> */}
      <Marquees />

      <div>
        <div className={isMobileView ? "container p-1 " : "container p-3"}>
          <div>
            <CallAgent />
          </div>
          <HeaderSection
            categoryLocation={categoryLocation}
            setCategoryLocation={setCategoryLocation}
            categoryBType={categoryBType}
            setCategoryBType={setCategoryBType}
          />
        </div>

        <div className="categorysection greencolor">
          <CategorySearching
            setCategoryLocation={setCategoryLocation}
            setCategoryBType={setCategoryBType}
          />
        </div>

        <div className="mt-3">
          <div className="d-flex align-items-center justify-content-center">
            <div className="toolTicker_container Assignment_mBLPadd">
              <div className="toolAdtext_container">
                <h2 className="tools_ad_heading">Who we are?</h2>
                <p className="SellerInf_DesSelling text-dark">
                  When jumping into the vast world of real estate investments,
                  one might experience the term "assignment sale," but its
                  meaning and implications are not always clear to everyone. An
                  assignment sale, especially in real estate includes a legally
                  binding course of action where the first buyer of a property
                  —the assignor—pass on their rights and commitments under the
                  contract to another second party— called the assignee—before
                  the property's completion. All the above discussion is to
                  elaborate what an assignment sale is, and to explore its
                  points of interest and impediments, and provide insights on
                  how to navigate this unique transaction effectively.
                </p>
              </div>
              <div className="toolAdImage_container">
                <img
                  src="/images/WhoWeAre.png"
                  alt="Buyer Freedom"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <div className="toolTicker_container Assignment_mBLPadd">
              <div className="toolAdImage_container">
                <img
                  src="/images/WhatIsAssignmentSale.png"
                  alt="Buyer Freedom"
                  loading="lazy"
                />
              </div>
              <div className="toolAdtext_container">
                <h2 className="tools_ad_heading">
                  What is an Assignment Sale?
                </h2>
                <p className="SellerInf_DesSelling text-dark">
                  Fundamentally, an assignment sale is not a direct sale of real
                  estate; instead, it is the transfer of a contract linked to
                  real estate. This situation typically arises in developments
                  where the property, such as a condominium or a newly
                  constructed housing project, remains unfinished. The initial
                  buyer has entered into an agreement to acquire the property
                  from a developer, and via an assignment sale, that buyer
                  transfers their rights to the property to another party before
                  taking ownership.
                </p>
              </div>
            </div>
          </div>

          <div className="d-flex align-items-center justify-content-center">
            <div className="toolTicker_container Assignment_mBLPadd">
              <div className="toolAdtext_container">
                <h2 className="tools_ad_heading">
                  How Do You Know if You Can Assign a Home?
                </h2>
                <p className="SellerInf_DesSelling text-dark">
                  Before considering an assignment sale, it is very important to
                  thoroughly verify or check the assignment sale, you are going
                  to purchase, whether the genuine purchase agreement allows you
                  for such a transaction? Many developers clearly mention
                  clauses that either prohibit assignment sales altogether or
                  require the original buyer’s permission to further proceed.It
                  is crucial to understand all these legal clauses point, and
                  sections before considering an assignment sale, as violating
                  any of these clauses can lead to notable legal and financial
                  consequences.
                </p>
              </div>
              <div className="toolAdImage_container">
                <img
                  src="/images/AssignHomes.png"
                  alt="Buyer Freedom"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          {isMobileView ? (
            <div className="justifycenter">
              <div>
                <img
                  className="Tab__ICon"
                  src={require("../Components/Images/icon.png")}
                  alt="AssignmentSold Logo"
                  loading="lazy"
                />
              </div>
            </div>
          ) : (
            <div className="text-center mb-5 mt-5 coloring ">
              <h2 className="diff font">
                The<span className="textgreen"> AssignmentSold.ca</span>{" "}
                Difference
              </h2>
              <p className="diff1">
                Forward thinking real estate trusted for over 25 years
              </p>
              <p className="diff1">Here’s how it works</p>
            </div>
          )}

          <div>
            <FullWidthTabs />
          </div>
        </div>

        {/* ------- AssignmetnSoldPromise Section ---------- */}
        <div>
          <OurPromiseFromBuyer />
        </div>

        {/* ------- AssignmetnSoldPromise Section ---------- */}
        <div>
          <LatestBlogs />
        </div>

        {/* ------- SellProperty  Section ---------- */}

        <section className="d-flex m-v-footer text-white container-fluid justify-content-between greencolor p-4">
          <div className="me-5 ">
            <h2 className="mar ft-11 font">
              Do You want to Sell Your Property?
            </h2>
          </div>
          <div className="me-5 ">
            <NavLink to="/sell" className="textcolor">
              <button className="btn font fbton ">
                List Your Property Now
              </button>
            </NavLink>
          </div>
        </section>

        <div style={{ marginTop: "4rem" }}>
          <Footer />
        </div>

        <ToastContainer />
      </div>
    </>
  );
};

export default Home;
