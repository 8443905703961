import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import IconButton from "@mui/material/IconButton";
import { IoIosShareAlt } from "react-icons/io";
import { Popover } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useState } from "react";
export default function ShareFeature({ linkSlug }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopClose = () => {
    setAnchorEl(null);
  };
  const [, setCopied] = useState(false);

  const handleCopyLink = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
      handlePopClose();
    }, 1500);
  };

  const handleFacebookLinkShare = () => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${`https://assignmentsold.ca/assignment-sale/`}${linkSlug}`
    );
  };
  const handleLinkdinLinkShare = () => {
    window.open(
      `https://www.linkedin.com/sharing/share-offsite/?url=https://assignmentsold.ca/assignment-sale/${linkSlug}`
    );
  };
  const handleWhatsAppLinkShare = () => {
    window.open(
      `https://wa.me/?text=https://assignmentsold.ca/assignment-sale/${linkSlug}`
    );
  };
  const handletwitterLinkShare = () => {
    window.open(
      `https://twitter.com/intent/tweet?url=https://assignmentsold.ca/assignment-sale/${linkSlug}`
    );
  };

  return (
    <div>
      <div className="p-0 aaligner2">
        <div className="p-0">
          <IconButton
            aria-describedby="simple-popover"
            variant="contained"
            onClick={handlePopClick}
          >
            <IoIosShareAlt
              style={{ fontSize: "30px" }}
              className="faviconcolorgreen p-0"
            />
          </IconButton>
          <Popover
            id="simple-popover"
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handlePopClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{
              "& .MuiPopover-paper": {
                borderRadius: "52px",
                boxShadow: "none",
              },
            }}
          >
            <Typography sx={{ p: 1 }}>
              <CopyToClipboard
                text={window.location.href}
                onCopy={handleCopyLink}
                className="share__logos m-1"
              >
                {/* <a
                  href="https://www.facebook.com/sharer/sharer.php?u="
                  target="_blank"
                  rel="noopener noreferrer"
                  className="share__logos m-1"
                > */}
                <img
                  src={require("../../images/facebooklogo.png")}
                  className="share__logos"
                  alt="facebook logo"
                  onClick={handleFacebookLinkShare}
                  loading="lazy"
                />
                {/* </a> */}
              </CopyToClipboard>
              <CopyToClipboard
                text={window.location.href}
                onCopy={handleCopyLink}
                className="share__logos"
              >
                {/* <a
                  href={`https://www.instagram.com/share?url=${window.location.href}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="share__logos"
                > */}
                <img
                  src={require("../../images/linkdinLogo.png")}
                  className="share__logos"
                  alt="Linkdin logo"
                  onClick={handleLinkdinLinkShare}
                  loading="lazy"
                />
                {/* </a> */}
              </CopyToClipboard>
              <CopyToClipboard
                text={window.location.href}
                onCopy={handleCopyLink}
                className="share__logos mx-2"
              >
                {/* <a
                  href={`https://twitter.com/intent/tweet?url=${window.location.href}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="share__logos mx-2"
                > */}
                <img
                  src={require("../../images/twitterlogo.png")}
                  className="share__logos"
                  alt="twitter logo"
                  onClick={handletwitterLinkShare}
                  loading="lazy"
                />
                {/* </a> */}
              </CopyToClipboard>
              <CopyToClipboard
                text={`${window.location.href}/${linkSlug}`}
                onCopy={handleCopyLink}
              >
                {/* <a
                  href={`https://wa.me/?text=${window.location.href}/${linkSlug}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="share__logos"
                > */}
                <img
                  src={require("../../images/whatsapplogo.png")}
                  className="share__logos"
                  alt="whatsapp logo"
                  onClick={handleWhatsAppLinkShare}
                  loading="lazy"
                />
                {/* </a> */}
              </CopyToClipboard>
            </Typography>
          </Popover>
        </div>
      </div>
    </div>
  );
}
