import "./MortgageAdvisor.css";
import { MenuItem } from '@mui/material';
import { Box, InputLabel, FormControl } from "@mui/material";
import Select from "@mui/material/Select";
import { useState } from "react";
import Mortgagedata from "./MortgageAdvisorData";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { TbMessageDots } from "react-icons/tb";
import { IoCallOutline } from "react-icons/io5";
import { MdKeyboardArrowRight } from "react-icons/md";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";
import { BsSearch } from "react-icons/bs";
import { useMemo } from "react";
import MortgageBanner from '../../images/MortgageBanner.png';
import HelmetLayout from "../../Utils/HelmetLayout/Helmet";

const MortgageAdvisor = () => {
  const navigate = useNavigate();

  //Select Language
  const [language, setLanguage] = useState("");
  const [mortReal, setMortReal] = useState("Mortgage");
  const [mortRealbtn, setMortRealbtn] = useState("Mortgage");
  const handleLanguageChane = (event) => {
    setLanguage(event.target.value);
  };

  const [searchInput, setSearchInput] = useState("");

  // Filtered data based on language and search input
  const filteredData = useMemo(() => {
    let filtered = Mortgagedata;

    // Filter by language
    if (language) {
      filtered = filtered.filter(
        (item) => item.language.toLowerCase() === language.toLowerCase()
      );
    }

    // Filter by search input
    if (searchInput) {
      const lowercasedInput = searchInput.toLowerCase();
      filtered = filtered.filter(
        (item) =>
          item.name.toLowerCase().includes(lowercasedInput) ||
          item.address.toLowerCase().includes(lowercasedInput)
      );
    }

    return filtered;
  }, [language, searchInput]);

  //send Data to details Page
  const handleDetails = (item) => {
    const detailsData = item;
    navigate(`/mortgage-advisor/${item.name}`, { state: { detailsData } });
  };
  const handleLabelChange = () => {
    setMortReal("Mortgage");
    setMortRealbtn((prevLabelBtn) => (prevLabelBtn === "Mortgage" ? "Mortgage" : "Mortgage"));
  };
  const handleLabelChange2 = () => {
    setMortReal("Real Estate");
    setMortRealbtn((prevLabelBtn) => (prevLabelBtn === "Mortgage" ? "Real Estate" : "Real Estate"));
  };

  return (
    <div className="MortgageMain">

   <HelmetLayout pageName={'mortgage-advisor'}  canonicalLink={'mortgage-advisor'}/>

      <div className="MortgaegMain_image">
        <div className="image_color">
          <img src={MortgageBanner} alt="Mortgage Banner" loading="lazy"/>
        </div>

        <div className="mortgageImage_HeadingMain">
          <div className="mortgage_Search">
            <input
              type="text"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <BsSearch className="mortgage_searchIcon" />
          </div>

          <div className="mortgageImage_heading">
            <h1>Find a Mortgage Advisor</h1>
          </div>
          <div className="mortgageImage_heading mortgage_lang">
            <h3>
              "Expertise, Excellence, and Extraordinary Service – All in Your{" "}
              <span className="mortgageImage_language">
                {" "}
                Preferred Language!{" "}
              </span>
              "
            </h3>
          </div>
          <div className="mortgageImage_Des mt-4">
            <h5>
              "We're Mortgage Wizards with Big Brains and Big Hearts for Your
              <span className="mortagage_better">
                {" "}
                Better Mortgage Experience!"
              </span>
            </h5>
          </div>
        </div>
      </div>

      <div className="mt-5">
        <h3 className="MortgageAgent_directory">{mortRealbtn} Agent Directory</h3>
      </div>

      <div className="container DropDownAndAgent MortgageBtn_dropDown mt-4">
        <div className="Spoken_dropDown">
          <Box>
            <FormControl fullWidth>
              <InputLabel id="SpokenLanguages">All Spoken Language</InputLabel>
              <Select
                labelId="All Spoken Languages"
                id="SpokenLanguages"
                value={language}
                label="All Spoken Languages"
                onChange={handleLanguageChane}
                sx={{
                  color: "#0253A0",
                  fontWeight: "600",
                }}
                IconComponent={KeyboardArrowDownIcon}
              >
                <MenuItem value={"English"}>English</MenuItem>
                <MenuItem value={"German"}>German</MenuItem>
                <MenuItem value={"Chineese"}>Chineese</MenuItem>
                <MenuItem value={"French"}>French</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </div>
        <div className="">
          <button className={`${mortReal === 'Mortgage' ? 'mortgageAgent_find' : 'mortgage_agentUnselectBtn'} me-2`} onClick={() => handleLabelChange()}>Mortgage Agent</button>
          <button className={`${mortReal === 'Real Estate' ? 'mortgageAgent_find' : 'mortgage_agentUnselectBtn'}`} onClick={() => handleLabelChange2()}>Real Estate Agent</button>
        </div>
      </div>

      <div className="mortgage_Cards container">
        {filteredData.length === 0 ? (
          <div className="d-flex flex-column justify-content-center align-items-center mt-5">
            <div className="no_Found__img">
              <img src={require("../../images/noResultFound.png")} alt="Result not found" loading="lazy"/>
            </div>
            <div className="no__FoundText">
              <p>We appologize, but it seems that no result were found for your search</p>
            </div>
          </div>
        ) : (
          filteredData?.map((item, index) => (
            <div
              className="mortgage_card"
              key={index}
              onClick={() => handleDetails(item)}
            >
              <div className="mortgageCard_1">
                <div className="mortgage_cardImage">
                  <img src={item.image} alt={item?.name} loading="lazy"/>
                </div>
                <div className="agent_information">
                  <p className="mortgageagent_name">
                    <strong>{item?.name}</strong>
                  </p>
                  <p className="mortgageagent_address">{item.address}</p>
                  <p className="mortgageagent_language">{`${item?.language} Language`}</p>
                </div>
                <div className="mortgage_chip">
                  <p>{mortReal} Agent</p>
                </div>
              </div>
              <div className="mortgageIcons">
                <div className="mortgagemessageIcon">
                  <TbMessageDots className="mortgage_icons" />
                </div>
                <div className="mortgagemessageIcon mortgageCallIcon">
                  <IoCallOutline className="mortgage_icons" />
                </div>
              </div>
              <div className="mortgagekeyIcon">
                <MdKeyboardArrowRight className="mortgageRight_icon" />
              </div>
            </div>
          ))
        )}
      </div>

      <div className="mt-5">
        <Footer className="mt-2" />
      </div>
    </div>
  );
};

export default MortgageAdvisor;
