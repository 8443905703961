import React from "react";
import { Routes, Route } from "react-router-dom";
import WebsitesRoutes from "./WebsitesRouts";
import DashboardRoutes from "./DashboardRoutes";
import NotFoundPage from "../Components/404Page/NotfoundPage";

const AppRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/dashboard/*" element={<DashboardRoutes />} />
        <Route path="*" element={<WebsitesRoutes />} />
        <Route element={<NotFoundPage />} />
      </Routes>
    </>
  );
};
export default AppRoutes;
