import * as React from "react";
import "./MapandListing.css";
import { useSelector } from "react-redux";
import CardMain from "./CardMain";
import CardMainSkeleton from "./CardMainSkeleton";


export default function MapListingCards({isLoading, setDrawerLoading,handleHoveredListCard }) {
  const filterDataSlice = useSelector((state) => state.filterDataSlice);
  const reduxStoreData = filterDataSlice?.flat();

   return (
    <div className="mapListing_allcards">
       {isLoading ?
        Array.from(new Array(8))?.map((_, index) => (
          <CardMainSkeleton key={index} isMainCard={true}/>
        )) :
            reduxStoreData?.map((item, index) => (
              <CardMain key={index} item={item} isLoading={isLoading} setDrawerLoading={setDrawerLoading} cardPath={"assignment-sale/"} handleHoveredListCard={handleHoveredListCard}/>
            ))}
    </div>
  );
}
