import React, { useEffect, useState } from 'react'
import '../../SignUp/Signup.css'
import '../../../Components.css'
import { Checkbox, FormControlLabel, FormGroup, TextField } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import { NavLink } from 'react-router-dom'
import { UserSignUpSchema } from './UserSignUpSchema'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useFormik } from 'formik'
import Loader from '../../Loader/Loader'
import baseURL from '../../../config'
import { useNavigate } from 'react-router-dom'
import { addProfileData } from '../../../ReduxStore/Slices/ProfileDataSlice'
import { useDispatch } from 'react-redux'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { addToken } from '../../../ReduxStore/Slices/TokenTimeSlice'
import Cookies from 'universal-cookie'
// import { GoogleOAuthProvider } from '@react-oauth/google'
// import GoogleSignUpButton from './GoogleSignUpButton'
import PhoneInput from 'react-phone-number-input'

export default function UserSignUp ({userType}) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const cookies = new Cookies()
  const [isLoading, setIsLoading] = useState(false)
  // const [isChecked, setIsChecked] = useState(false);
  const [isPassChange, setIsPassChange] = useState(false)
  const [isConPassChange, setIsConPassChange] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [passHasLowerCase, setPassHasLowerCase] = useState(false)
  const [passHasCharacter, setPassHasCharacter] = useState(false)
  const [passHasCompleteLength, setPassHasCompleteLength] = useState(false)
  const [passHasNumber, setPassHasNumber] = useState(false)
  const formInitialValues = {
    fname: '',
    lname: '',
    password: '',
    email: '',
    cno: '',
    cpassword: '',
    selectedCountry: 'CA',
    termsAndConditions: false,
  }

   const handlePhoneInputChange = value => {
    formik.setFieldValue('cno', value)
  }

  const handleCheckboxChange = ()=>{
    formik.setFieldValue('termsAndConditions', !formik.values.termsAndConditions);
  }

  const formik = useFormik({
    initialValues: formInitialValues,
    validationSchema: UserSignUpSchema,
    onSubmit: async (values, { resetForm }) => {
      // if (!isChecked) {
      //   toast.error("Please agree to the Terms and Conditions");
      //   return;
      // }
      setIsLoading(true)
      const headers = {
        'Content-Type': 'application/json'
      }
      const data = JSON.stringify({
        Email: values.email,
        Password: values.password,
        FirstName: values.fname,
        LastName: values.lname,
        Phone: values.cno,
        Type: userType,
      })
      try {
        const response = await axios.post(`${baseURL}/api/UserRegister`, data, {
          headers
        })
        if (response.data.result === 1) {
          const jwt_token = response.data.data.refreshToken
          const exp_time = response.data.data.refreshTokenExpiryTime
          dispatch(addToken(exp_time))
          const refreshTokenExpiry = new Date(exp_time)
          cookies.set('jwt_authorization', jwt_token, {
            expires: refreshTokenExpiry
          })
          localStorage.setItem('anonymous', 0)
          dispatch(addProfileData(response.data))
          resetForm()
          toast.success(response.data.message)
          navigate('/email-confirmation')
        } else {
          toast.error(response.data.error[0])
        }
      } catch (error) {
        toast.error(error.message)
        console.error(error.message)
      } finally {
        setTimeout(() => {
          setIsLoading(false)
        }, 2000)
      }
    }
  })

  const [showPassword, setShowPassword] = useState(false)
  const [showCPassword, setShowCPassword] = useState(false)

  const handleClickShowPassword = () => setShowPassword(show => !show)
  const handleClickShowCPassword = () => setShowCPassword(show => !show)

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  const handlePasswordChange = event => {
    const newPassword = event.target.value
    formik.setFieldValue('password', newPassword)
    validatePassword(newPassword)
  }

  const validatePassword = password => {
    if (/(?=.*[a-z])(?=.*[A-Z])/.test(password)) {
      setPassHasLowerCase(true)
    } else {
      setPassHasLowerCase(false)
    }

    if (/.*[0-9].*/.test(password)) {
      setPassHasNumber(true)
    } else {
      setPassHasNumber(false)
    }

    if (password.length >= 8) {
      setPassHasCompleteLength(true)
    } else {
      setPassHasCompleteLength(false)
    }

    if (/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      setPassHasCharacter(true)
    } else {
      setPassHasCharacter(false)
    }
  }

  useEffect(() => {
    const autofill = cookies.get('LoginObject')
    if (autofill) {
      // Use formik.setValues to set the initial values
      formik.setValues({
        fname: autofill.name || '',
        email: autofill.mail || '',
        cno : autofill.phoneNumber || '',
      })
    }
    // eslint-disable-next-line
  }, [])

  return (
    // <GoogleOAuthProvider client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <div>
        <div className='container centered  aligning'>
          <div className='fieldandbuttons'>
            <p className='font fieldhead m-0'>
              <strong> First Name</strong>
            </p>{' '}
            <FormControl>
              <TextField
                sx={{
                  width: '386px',
                  borderRadius: '4px',
                  marginBottom: '20px'
                }}
                size='small'
                className='fieldsettings'
                value={formik.values.fname}
                onChange={formik.handleChange}
                name='fname'
                id='outlined-basic1'
                label={<span className='font'>Enter Your First Name</span>}
                variant='outlined'
              />
              {isSubmitted ? (
                <span style={{ color: 'red' }}>{formik.errors.fname}</span>
              ) : null}
            </FormControl>
            <p className='font fieldhead m-0'>
              <strong> Last Name</strong>
            </p>{' '}
            <FormControl>
              <TextField
                size='small'
                sx={{
                  width: '386px',
                  borderRadius: '4px',
                  marginBottom: '20px'
                }}
                className='fieldsettings'
                value={formik.values.lname}
                onChange={formik.handleChange}
                name='lname'
                id='outlined-basic2'
                label={<span className='font'>Enter Your Last Name</span>}
                variant='outlined'
              />
              {isSubmitted ? (
                <span style={{ color: 'red' }}>{formik.errors.lname}</span>
              ) : null}
            </FormControl>
            <p className='font fieldhead m-0'>
              <strong> Email</strong>
            </p>{' '}
            <FormControl>
              <TextField
                size='small'
                sx={{
                  width: '386px',
                  borderRadius: '4px',
                  marginBottom: '20px'
                }}
                className='fieldsettings'
                value={formik.values.email}
                onChange={formik.handleChange}
                name='email'
                id='outlined-basic3'
                label={<span className='font'>Enter Email Address</span>}
                variant='outlined'
              />
              {isSubmitted ? (
                <span style={{ color: 'red' }}>{formik.errors.email}</span>
              ) : null}
            </FormControl>
            <p className='font fieldhead m-0'>
              <strong> Phone Number</strong>
            </p>
            <FormControl className='w-100'>
              <PhoneInput
                size='small'
                international
                countryCallingCodeEditable={false}
                defaultCountry='CA'
                value={formik.values.cno}
                onChange={handlePhoneInputChange}
                name='phoneNumber'
                id='phoneNumber'
                className='Phone_input signUp_numberInput'
                placeholder='Enter Your Phone Number'
              />
              {isSubmitted ? (
                <span style={{ color: 'red' }}>{formik.errors.cno}</span>
              ) : null}
            </FormControl>
            <p className='font fieldhead m-0'>
              <strong>Password</strong>
            </p>
            <div>
              <FormControl
                className='fieldsettings2 pass_input position-relative'
                variant='outlined'
              >
                <InputLabel htmlFor='outlined-adornment-password'>
                  Password
                </InputLabel>
                <OutlinedInput
                  size='small'
                  value={formik.values.password}
                  onChange={e => {
                    handlePasswordChange(e)
                    setIsPassChange(true)
                  }}
                  name='password'
                  id='outlined-adornment-password1'
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  }
                  label={<span className='font'>Password</span>}
                />
                {isPassChange && formik.errors.password ? (
                  isPassChange &&
                  formik.errors.password !== 'This field is required' ? (
                    <div className='pass_suggestion_box'>
                      <h6>Password must contain the following:</h6>
                      <p
                        className={
                          passHasLowerCase ? 'valid_passw' : 'invalid_passw'
                        }
                      >
                        Lowercase and UpperCase
                      </p>
                      <p
                        className={
                          passHasNumber ? 'valid_passw' : 'invalid_passw'
                        }
                      >
                        A number
                      </p>
                      <p
                        className={
                          passHasCharacter ? 'valid_passw' : 'invalid_passw'
                        }
                      >
                        A special character (@, ! ,#)
                      </p>
                      <p
                        className={
                          passHasCompleteLength
                            ? 'valid_passw'
                            : 'invalid_passw'
                        }
                      >
                        Minimum 8 characters
                      </p>
                    </div>
                  ) :  <span style={{ color: 'red' }}>
                  {formik.errors.password}
                </span>
                ) : null}
              </FormControl>
            </div>
            <p className='font fieldhead mt-4 mb-0'>
              <strong>Confirm Password</strong>
            </p>
            <div>
              <FormControl
                className='fieldsettings2 pass_input'
                variant='outlined'
              >
                <InputLabel htmlFor='outlined-adornment-password'>
                  Re-Enter Password
                </InputLabel>
                <OutlinedInput
                  size='small'
                  value={formik.values.cpassword}
                  onChange={e => {
                    formik.handleChange(e)
                    setIsConPassChange(true)
                  }}
                  name='cpassword'
                  id='outlined-adornment-password5'
                  type={showCPassword ? 'text' : 'password'}
                  endAdornment={
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowCPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {showCPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  }
                  label={<span className='font'>Re-Enter Password</span>}
                />
                {isConPassChange ? (
                  <span style={{ color: 'red' }}>
                    {formik.errors.cpassword}
                  </span>
                ) : null}
              </FormControl>
            </div>
            <FormGroup className='mt-2'>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.termsAndConditions}
                    name='termsAndConditions'
                    onChange={handleCheckboxChange}
                  />
                }
                label={
                  <span className='font'>
                    I agree all{' '}
                    <NavLink to='/terms-and-conditions' className='terms'>
                      Terms and Conditions
                    </NavLink>
                  </span>
                }
              />
              {isSubmitted ? (
                <span style={{ color: 'red' }}>
                  {formik.errors.termsAndConditions}
                </span>
              ) : null}
            </FormGroup>
            {isLoading ? (
              <div className='aligncenter justifycenter'>
                <Loader />
              </div>
            ) : (
              <div>
                <button
                  type='submit'
                  onClick={() => {
                    formik.handleSubmit()
                    setIsSubmitted(true)
                    setIsPassChange(true)
                    setIsConPassChange(true)
                  }}
                  className='CreateButton'
                  style={{marginTop: '10px'}}
                >
                  Create Account
                </button>
              </div>
            )}
            {/* <div className='row'>
              <div className='font  col-12 '>
                <p className='m-0 justify'>OR</p>
              </div>
            </div> */}
            {/* <div className='row'>
              <div className='col-12'>
                <GoogleSignUpButton
                  setIsLoading={setIsLoading}
                  role={userType}
                />
              </div>
            </div> */}
            <div className='d-flex justify-content-center pt-3'>
              <div className='p-0'>
                <p className=' font stflot1 m-0'>
                  Already have an Account?{' '}
                  <NavLink
                    to='/login'
                    className='font stflot2 pl-2'
                    style={{ color: 'rgb(2, 84, 158)', paddingLeft: '6px' }}
                  >
                    <strong>Login</strong>
                  </NavLink>
                </p>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    // </GoogleOAuthProvider>
  )
}
