import { useState, useEffect } from "react";
import DashboardLayout from "../../Dashboard";
import { toast, ToastContainer } from 'react-toastify';
import { RotatingLines } from "react-loader-spinner";
import { IconButton, InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate, useLocation, useParams } from "react-router-dom";
import '../NewUserListing/UserListings.css';
import baseURL from "../../../config";
import Cookies from 'universal-cookie';
import axios from "axios";
import { useDispatch } from "react-redux";
import { removePdfData } from "../../../ReduxStore/Slices/PdfDownloadSlice";
import Drawer from "@mui/material/Drawer";
import DrawerContent from "../../../HomePage/SubComponents/MapandListing/DrawerContent";
import Loader from "../../../HomePage/SubComponents/MapandListing/Loader";
import StatisticsListingCard from "./Components/StatisticsLisingCard";
import { Helmet } from 'react-helmet';


const AllStatisticsListing = () => {

    const { id } = useParams();
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const cookies = new Cookies();
    const jwt_token = cookies.get("jwt_authorization");
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [searchBy, setSearchBy] = useState('ProjectName')
    const [btnStatus, setBtnStatus] = useState('');
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState([])
    const [searchValue, setSearchValue] = useState('');
    const [selectedCards, setSelectedCards] = useState([]);
    const [totalPageCount, setTotalPageCount] = useState('');
    const [hasMoreContent, setHasMoreContent] = useState(false);
    const [contentPage, setContentPage] = useState(1);
    const [loadMore, setLoadMore] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState();

    const fetchUserListingData = async (query) => {
        try {
            const headers = {
                Authorization: `Bearer ${jwt_token}`,
            };
            const data = {
                Status: id,
                Term: query,
                TermTitle: searchBy, //ProjectName,Id,AssignmentPrice,InteriorSize
                page: contentPage,
                UserId: "2"
            }
            const response = await axios.post(
                `${baseURL}/api/Admin-User/GetAssignmentForAll`, data, { headers },
            );
            if (response) {
                const ListingData = response?.data?.data?.assignments;
                if (contentPage === 1) {
                    setData(ListingData);
                } else {
                    setData((prevData) => [...prevData, ...ListingData])
                }
                setTotalPageCount(Math.ceil(response?.data?.data?.count / 20))
                setLoadMore(false)
                setLoader(false)
            }
        } catch (error) {
            setLoader(false)
            setLoadMore(false)
            toast.error(error);
        }
    };


    useEffect(() => {
        setBtnStatus(id)
        if (!jwt_token) {
            navigate('/login', { state: { returnUrl: `/dashboard//stats/${id}` } })
            return;
        }
        if (contentPage === 1) {
            setLoader(true)
        }
        fetchUserListingData(searchValue)
        // eslint-disable-next-line
    }, [id, contentPage])


    const handleStatusBtnClick = (status) => {
        setLoadMore(false)
        setHasMoreContent(false)
        setContentPage(1)
        setData([])
        navigate(`/dashboard/stats/${status}`)
    }

    //Change PropertyStatus
    const ChangePropertyStauts = async (status) => {
        try {
            const headers = {
                Authorization: `Bearer ${jwt_token}`,
            };

            const payload = selectedCards.map(Uid => ({
                Uid: Uid,
                status: status,
            }));

            const response = await axios.post(
                `${baseURL}/api/Admin-User/UpdateListing`, payload,
                { headers }
            );

            if (response) {
                setSelectedCards([])
                setLoader(true)
                setContentPage(1)
                fetchUserListingData(searchValue)
                setBtnDisabled(false)
            }

        } catch (error) {
            setLoader(false)
            setSelectedCards([])
            setBtnDisabled(false)
            toast.error(error);
        }
    };

    const handleChangePropStatusBtn = (status) => {
        if (!jwt_token) {
            navigate('/login');
        }
        setBtnDisabled(true)
        setLoader(true)
        ChangePropertyStauts(status)
    }


    const debounce = (func, delay) => {
        let timer;
        return function (...args) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    };

    const debouncedSearch = debounce((query) => {
        if (query.length > 0 || query ===  '') {
            fetchUserListingData(query)
        }
        else {
            setLoader(false)
        }
    }, 500);

    const handleSearchInputChange = (event) => {
        if (!jwt_token) {
            navigate('/login');
            return;
        }
        setLoader(true)
        setContentPage(1)
        setSearchValue(event.target.value)
        debouncedSearch(event.target.value);
    };

    const HandleMoreContent = () => {
        setLoadMore(true)
        setContentPage((prevPage) => prevPage + 1);
    }

    useEffect(() => {
        if (contentPage === totalPageCount || totalPageCount === 0) {
            setHasMoreContent(false);
        } else {
            setHasMoreContent(true);
        }
    }, [contentPage, totalPageCount]);


    //Drawer open even refresh
    const [drawerLoading, setDrawerLoading] = useState(false)
    const drawerSlug = params.get('drawerSlug');
    const location = useLocation();
    const slugPresent = drawerSlug;
    const [draweritem, setDrawerItem] = useState();
    const [itemID, setItemID] = useState(1);
    const { searchData } = location.state || {};
    useEffect(() => {
        if (searchData === undefined) {
            if (slugPresent) {
                axios
                    .get(`${baseURL}/api/Assignment/GetAssignment/${slugPresent}`)
                    .then((response) => {
                        if (response?.data?.data) {
                            setDrawerItem(response?.data?.data);
                            setOpenDrawer(response?.data?.data?.id);
                            setItemID(response?.data?.data?.id);
                            setDrawerLoading(false)
                        } else {
                            toast.error("There is no Data for this Assignment");
                            navigate(`/dashboard/stats/${id}`);
                            setDrawerLoading(false)
                        }
                    })
                    .catch((error) => {
                        navigate(`/dashboard/stats/${id}`);
                        toast.error('Error in fetching Listing');
                        setDrawerLoading(false)
                    });
            }
        }
        // eslint-disable-next-line
    }, [slugPresent]);

    useEffect(() => {
        const handlePopstate = () => {
            const currentPath = window.location.pathname;
            if (currentPath === `/dashboard/stats/${id}`) {
                setOpenDrawer(null);
                setDrawerLoading(false)
                setItemID(1);
                dispatch(removePdfData());
            }
        };

        window.addEventListener("popstate", handlePopstate);

        return () => {
            window.removeEventListener("popstate", handlePopstate);
        };
        // eslint-disable-next-line
    }, [dispatch]);


    const [openDrawer, setOpenDrawer] = useState(null);
    const toggleDrawer = (anchor, itemId) => () => {
        setOpenDrawer(null);
        setItemID(1);
        dispatch(removePdfData());
        navigate(`/dashboard/stats/${id}`);
    };

    const isDrawerOpen = (itemId) => {
        // eslint-disable-next-line
        return openDrawer == itemId;
    };


    return (
        <DashboardLayout>
            
            <Helmet>
                <title>AssignmentSold.ca</title>
            </Helmet>
            
            <ToastContainer />
            <div className="row br-mobile4remMarginTop"></div>
            <section className='page-content br-page-content'>
                <div className="br-container mb-3">
                    <div className="headingtitlelister">
                        <h3 className="Single_allListingHeading m-0 d-flex align-items-center">
                            <ArrowBackIosNewIcon className='mx-1 singleUserBreadCrumbs_arrow' />
                            <span style={{ cursor: "pointer" }} onClick={() => navigate('/dashboard/stats')}>Statistics</span>
                        </h3>
                    </div>
                    <div className="br-filterconntainer">
                        <div className="br-datebtn">
                            {(btnStatus === 'Pending' || btnStatus === 'Suspended') && (
                                <button className='UserSingleListing_statusBtn' disabled={selectedCards.length === 0 || btnDisabled} onClick={() => handleChangePropStatusBtn('Active')}>
                                    Active
                                </button>
                            )}
                            {(btnStatus === 'Active' || btnStatus === 'Suspended') && (
                                <button className='UserSingleListing_statusBtn' disabled={selectedCards.length === 0 || btnDisabled} onClick={() => handleChangePropStatusBtn('Pending')}>
                                    Pending
                                </button>
                            )}
                            {(btnStatus === 'Pending' || btnStatus === 'Active') && (
                                <button className='UserSingleListing_statusBtn' disabled={selectedCards.length === 0 || btnDisabled} onClick={() => handleChangePropStatusBtn('Suspended')}>
                                    Suspended
                                </button>
                            )}
                        </div>

                        <div className="d-flex br-searchInputAndTitle">
                            <form
                                onSubmit={(e) => e.preventDefault()}
                                className="border d-flex end_round2 br_serachInp w-100"
                            >
                                <IconButton
                                    type="button"
                                    sx={{ p: "10px" }}
                                    aria-label="search"
                                >
                                    <SearchIcon />
                                </IconButton>
                                <InputBase
                                    size="medium"
                                    sx={{ flex: 1, paddingLeft: 1 }}
                                    placeholder={"Search by title,id and price"}
                                    value={searchValue}
                                    onChange={handleSearchInputChange}
                                />
                            </form>
                            <select
                                className="form-select TitleMenuWidth end_round m-0 br_serachSelect"
                                aria-label="Default select example"
                                value={searchBy}
                                onChange={(e) => setSearchBy(e.target.value)}
                            >
                                <option value="Id">ListingId</option>
                                <option value="ProjectName">Project Name</option>
                                <option value="AssignmentPrice">Price</option>
                                <option value="InteriorSize">Sqft</option>
                            </select>
                        </div>
                    </div>
                </div>

                {/* ======== Status Filter Section ======== */}

                <div className="br-Statusbuttons single_userListingStatusBtn">
                    <button className={`btn ${btnStatus === 'Active' ? 'br_ActivestatusBtn' : 'br_statusBtn'}`} onClick={() => handleStatusBtnClick('Active')}>
                        Active
                    </button>
                    <button className={`btn ${btnStatus === 'Pending' ? 'br_ActivestatusBtn' : 'br_statusBtn'}`} onClick={() => handleStatusBtnClick('Pending')}>
                        Pending
                    </button>
                    <button className={`btn ${btnStatus === 'Suspended' ? 'br_ActivestatusBtn' : 'br_statusBtn'}`} onClick={() => handleStatusBtnClick('Suspended')}>
                        Suspended
                    </button>
                </div>

                {/* ======== Card Section ======== */}
                {loader ?
                    <center className="text-center mt-4">
                        <RotatingLines
                            strokeColor="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="70"
                            visible={loader}
                        />
                    </center>
                    :
                    data?.length === 0 ?
                        <>
                            <div className="no__FoundMain font">
                                <div className="no__Found__img">
                                    <img className='not_found_img_mobile' src={require("../../../images/noResultFound.png")} alt="Result not found" loading="lazy"/>
                                </div>
                                <div className="no__FoundText font-family-regular not_found_Text_mobile">
                                    <p>No RecordFound</p>
                                </div>

                            </div>
                        </>
                        :
                        <>
                            <div className="d-flex flex-wrap ActiveUserListing_card mt-4 mb-4">
                                {data?.map((item, index) => {
                                    return (
                                        <StatisticsListingCard key={index} setDrawerLoading={setDrawerLoading} selectedCards={selectedCards} setSelectedCards={setSelectedCards} item={item} />
                                    )
                                })}
                            </div>
                            {hasMoreContent ? (
                                <div className="justifycenter aligncenter mb-4">
                                    {loadMore ? (
                                        <RotatingLines width={50} strokeColor="grey" />
                                    ) : (
                                        <button
                                            className="btn btn-primary font"
                                            onClick={HandleMoreContent}
                                            disabled={loadMore}
                                        >
                                            Load more
                                        </button>
                                    )}
                                </div>
                            ) : null}
                        </>
                }
            </section>
            {drawerLoading ? (
                <div className="scrollLoader fav_scrollLoader">
                    <Loader size="80" />
                    <p style={{ color: "#F8A23E", fontWeight: 600 }}>Loading....</p>
                </div>
            ) : (
                <Drawer
                    anchor="right"
                    open={isDrawerOpen(itemID)}
                    onClose={toggleDrawer("right", itemID)}
                >
                    <DrawerContent
                        item={draweritem}
                        anchor={"right"}
                        toggleDrawer={toggleDrawer}
                    />
                </Drawer>
            )}
        </DashboardLayout>
    )
}

export default AllStatisticsListing;