import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import SearchIcon from "@mui/icons-material/Search";
import './Blogs.css';
import axios from "axios";
import baseURL from '../../config';
import { ToastContainer, toast } from "react-toastify";
import BlogCard from './SubComponents/BlogsCards/BlogCard';
import Footer from '../Footer/Footer';
import BlogMainCard from './SubComponents/BlogsCards/BlogMainCard';
import BlogMobileBannerCard from './SubComponents/BlogsCards/BlogMobileBannerCard';
import useMediaQuery from '../../Dashboard/CustomHook/UseMediaQuery';
import { RotatingLines } from "react-loader-spinner";
import Skeleton from '@mui/material/Skeleton';
import HelmetLayout from '../../Utils/HelmetLayout/Helmet';

const Blogs = () => {

  const { id } = useParams()
  const navigate = useNavigate()
  const isTabAndDesktopScreen = useMediaQuery('(max-width: 968px)');
  const isPhoneScreen = useMediaQuery('(max-width: 768px)');
  const [loadMore, setLoadMore] = useState(false)
  const [hasMoreContent, setHasMoreContent] = useState(false);
  const [totalPageCount, setTotalPageCount] = useState('');
  const [contentPage, setContentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [searchLoading, setSearchLoading] = useState(false);
  const [data, setData] = useState([]);
  const [blogCategoryData, setBlogCategoryData] = useState('')
  const [blogAllCategoryData, setBlogAllCategoryData] = useState('')
  const [firstTimeLoad, setFirstTimeLoad] = useState(true)

  const fetchData = async (termValue) => {
    try {
      const bodyData = {
        page: contentPage,
        category: id,
        term: termValue ? termValue : ''
      };
      const response = await axios.post(`${baseURL}/api/blogs`, bodyData);
      setData(response?.data?.data?.blogs)
      setTotalPageCount(Math.ceil(response?.data?.data?.count / 20));
      setLoading(false);
      setLoadMore(false);
      const categoryData = response?.data?.data?.blogCategorys?.find(category => category.slug === id);
      setBlogAllCategoryData(response?.data?.data?.blogCategorys)
      setBlogCategoryData(categoryData)
      setFirstTimeLoad(false)
      setSearchLoading(false)
    } catch (error) {
      toast.error(error);
      setLoadMore(false);
      toast.error('Error fetching data')
    }
  };


  useEffect(() => {
    fetchData('', false);
    // eslint-disable-next-line
  }, [contentPage, id]);

  useEffect(() => {
    if (!id) {
      navigate(`/blogs/buying`)
    }
    // eslint-disable-next-line
  }, [])

  const handleCategoryChange = (category) => {
    if (!loading && category !== id) {
      setLoading(true)
      setContentPage(1)
      setData([])
      navigate(`/blogs/${category}`)
    }
  }

  useEffect(() => {
    if (!loading) {
      if (contentPage === totalPageCount || totalPageCount === 0) {
        setHasMoreContent(false);
      } else {
        setHasMoreContent(true);
      }
    }
    // eslint-disable-next-line
  }, [contentPage, totalPageCount]);

  const HandleMoreContent = () => {
    setLoadMore(true)
    setContentPage((prevPage) => prevPage + 1);
  }

  const handleSearchInputChange = event => {
    setSearchLoading(true)
    setLoading(true)
    setData([])
    setContentPage(1)
    setTimeout(() => {
      fetchData(event.target.value);
    }, 600);
  }

  return (
    <>

      <HelmetLayout pageName={`blogs/${id}`} canonicalLink={`blogs/${id}`}/>

      <div>

        {/* Mobile Search Bar */}

        <div className='mbl_ArticleSearchBar'>
          <input
            type='text'
            placeholder='Search'
            onChange={handleSearchInputChange}
          />
          <SearchIcon style={{ color: '#222', fontSize: '24px' }} />
        </div>

        {/* Mobile Banner Card */}
        {(loading || (data?.length > 0 && !loading)) && (
          <div className='mbl_ArticleBannerCard'>
            <BlogMobileBannerCard item={data[0]} loading={loading} />
          </div>
        )}


        <div className='w-100 mt-2 d-flex align-items-center justify-content-center ArticleMSD_none'>
          {(loading && !searchLoading) ?
            <Skeleton variant="rounded" animation="wave" style={{ width: '97%', height: '320px' }} /> :
            <div className='Article_cover '>
              <img src={`${baseURL}/${blogCategoryData?.fileUrl}`} alt={`${blogCategoryData?.name}-cover`} loading="lazy"/>
              <div className='Article_coverContent'>
                <h1>{blogCategoryData?.title}</h1>
              </div>
            </div>
          }
        </div>

        {blogCategoryData?.tagLine && (
          <div className='Blogs_mainPara mt-5 px-3 ArticleMSD_none'>
            <p>
              {blogCategoryData?.tagLine}
            </p>
          </div>
        )}

        <div className='Article_Filter_andButton'>
          <div className='d-flex justify-content-between Article_card_button'>
            <div className='d-flex flex-column Article_filter_section'>
              {!firstTimeLoad && (
                <>
                  <div className='artile_Searching ArticleMSD_none'>
                    <input
                      type='text'
                      placeholder='Search'
                      onChange={handleSearchInputChange}
                    />
                    <SearchIcon style={{ color: '#7e7e7e', fontSize: '24px' }} />
                  </div>
                  {blogAllCategoryData?.map(category => (
                    <button
                      key={category.id}
                      className={`BlogPathBtn ${category.slug === id ? 'Blog_ActiveBtn' : ''}`}
                      onClick={() => handleCategoryChange(category.slug)}
                    >
                      {category.name}
                    </button>
                  ))}
                </>
              )}
            </div>

            <div className='Article_cardsSection'>

              {(loading || (data?.length > 0 && !loading)) && (
                <div className='ArticleMCD_none'>
                  <BlogMainCard loading={loading} item={data[0]} />
                </div>
              )}

              <div className='AllArticleRead_cards mt-4'>
                {(loading ? Array.from(new Array(5)) : data?.slice(!isPhoneScreen && isTabAndDesktopScreen ? 0 : 1, data?.length))?.map((item, index) => {
                  return <BlogCard item={item} key={index} loading={loading} />
                })}
              </div>
              {(!loading && data?.length === 0) &&
                <>
                  <div className="no__FoundMain font">
                    <div className="no__Found__img">
                      <img className='not_found_img_mobile' src={require("../../images/noResultFound.png")} alt="Result not found" loading="lazy"/>
                    </div>
                    <div className="no__FoundText font-family-regular not_found_Text_mobile">
                      <p>No RecordFound</p>
                    </div>

                  </div>
                </>}
            </div>
          </div>

          {hasMoreContent ? (
            <div className="justifycenter aligncenter mt-4">
              {loadMore ? (
                <RotatingLines width={50} strokeColor="grey" />
              ) : (
                <button
                  className="btn btn-primary font"
                  onClick={HandleMoreContent}
                  disabled={loadMore}
                >
                  Load more
                </button>
              )}
            </div>
          ) : null}

        </div>

        {/* -------Footer Section-----*/}

        <div style={{ marginTop: '5rem' }}>
          <Footer />
        </div>

        <ToastContainer />
      </div>
    </>
  )
}

export default Blogs
