import React from "react";


export default function WhatsInclude() {

  return (
    <div className="text-center">
      <div className="d-flex flex-column align-items-center">
        <h1 className="text-primary font-weight-bold">Everything an Agent can do... And More!</h1>

        <ul className="text-start mt-3" style={{ listStyle: 'disc' }}>
          <li>
            You can experience the benefits of being on REALTOR.CA (provided by PG
            Direct Realty LTD.)
          </li>
          <li>
            Professional signage, photos, virtual tours and social media postings.
          </li>
          <li className="text-break">
            Direct access to PG Direct Realty LTD.
          </li>
        </ul>

        <p className="text-center mt-4" style={{ fontWeight: '500' }}>
          <strong>Want cash back when buying your next property?</strong>
        </p>
        <center>
          <a href='#whatsInclude'>
            <button className="btn learn_moreBtnTabs text-center m-0">
              Learn More
            </button>
          </a>
        </center>
      </div>
    </div>
  );
}
