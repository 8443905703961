import React, { useEffect, useState } from 'react';
import { packageData } from './PkgsData';
import { Button, Carousel } from 'react-bootstrap';
import { GrNext, GrPrevious } from "react-icons/gr";
import './quotes.css';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from '@mui/material/Tooltip';
import { Typography } from '@mui/material';

const ExtraPkg = ({ selectedPackages, setSelectedPackages }) => {

    const [selectedOption, setSelectedOption] = useState({
        TopHomePageGallery: {
            days: 7,
            price: 5.5,
        },
        HomePageTicker: {
            days: 7,
            price: 7.0,
        },
        HighlightAdd: {
            days: 7,
            price: 8.0,
        },
        Tags: {
            days: 7,
            price: 10,
        },
    });

    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 800);

    useEffect(() => {
        function handleResize() {
            setIsMobileView(window.innerWidth <= 800);
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleDaysChange = (value, category) => {
        const selectedDays = parseInt(value, 10);
        const selectedPrice = calculatePrice(selectedDays, category);

        setSelectedOption((prevState) => ({
            ...prevState,
            [category]: {
                ...prevState[category],
                days: selectedDays,
                price: selectedPrice,
            },
        }));
    };

    const calculatePrice = (days, category) => {
        const categoryData = packageData[category];
        const selectedData = categoryData.find((data) => data.days === days);
        return selectedData ? selectedData.price : 0;
    };

    const handleAddToPackage = (category) => {
        const isCategorySelected = selectedPackages.some(pkg => pkg.category === category);

        if (isCategorySelected) {
            handleRemoveFromPackage(category);
        } else {
            const selectedPackage = {
                category,
                days: selectedOption[category].days,
                price: selectedOption[category].price,
            };
            setSelectedPackages((prevPackages) => [...prevPackages, selectedPackage]);

        }
    };

    const handleRemoveFromPackage = (category) => {
        const updatedPackages = selectedPackages.filter(pkg => pkg.category !== category);
        setSelectedPackages(updatedPackages);

    };

    const handleAddorRemovepkgIcon = (category) => {
        const isCategorySelected = selectedPackages.some(pkg => pkg.category === category);
        return isCategorySelected ? require("../Images/MinusIcon.png") : require("../Images/AddIcon.png");
    };

    const handleCarouselButtonText = (category) => {
        const isCategorySelected = selectedPackages.some(pkg => pkg.category === category);
        return isCategorySelected ? "Selected" : "Add to Package";
    };

    const customPrevIcon = (
        <GrPrevious className='fs-4 ' style={{ margin: "1rem 0 0 -4rem" }} />
    );

    const customNextIcon = (
        <GrNext className='fs-4 ' style={{ margin: "1rem -4rem 0 0" }} />
    );


    const PkgTitle = (title) => {
        switch (title) {
            case "TopHomePageGallery":
                return "Top Home Page Gallery"
            case "HomePageTicker":
                return "Home Page Ticker"
            case "HighlightAdd":
                return "Highlight Add"
            case "Tags":
                return "Hot Deal Tags"
                default :
                return "Hot Deal Tags"
        }
    }

    const [openTooltipIndex, setOpenTooltipIndex] = useState(null);

    const handleTooltipToggle = (index) => {
        setOpenTooltipIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const carouselItems = Object.keys(packageData).map((category) => {
        const firstPackage = packageData[category][0]; // Taking the first package for demonstration
        return (
            <Carousel.Item key={category} >
                <div className="d-flex justify-content-center aligncenter" >
                    <img
                        className=""
                        src={require(`../Images/${category}Icon.png`)} // Assuming your images are named based on the category
                        alt={`${category}`}
                        style={{ width: "150px" }}
                        loading="lazy"
                    />
                </div>

                <div className='font d-flex flex-column justify-content-center aligncenter' style={{ marginTop: "3rem" }}>
                    <div  >
                        <h4 className='fw-bold'>{PkgTitle(category)}</h4>
                    </div>
                    <div className='mt-3 d-flex flex-column justify-content-center aligncenter'>
                        <p style={{ fontSize: "16px", textAlign: "center" }}>
                            Get on average of {firstPackage.price} impressions/{firstPackage.days} days with a prime position on our homepage
                        </p>
                        <div>
                            <Button className='bg-black fs-5 py-2 px-4 border-black' onClick={() => handleAddToPackage(category)} disabled={handleCarouselButtonText(category) === "Selected"}>{handleCarouselButtonText(category)}</Button>
                        </div>
                    </div>
                </div>

            </Carousel.Item>
        );
    });

    return (
        <div className='d-flex w-100'>
            <div className='bg-white getquote_card_padding mob_pricing_packages me-3' style={{ borderRadius: '30px', width: "70%" }}>
                <div className='mt-4 w-100'>
                    <hr className={isMobileView ? 'd-none' : 'my-2'} />
                    <span className={isMobileView ? 'd-none' : 'd-flex'}>
                        <p className='col-md-6 m-0 ms-1 text-start font fw-bold'>Category</p>
                        <p className='col-md-2 m-0 text-start font fw-bold'>Price</p>
                        <p className='col-md-4 font m-0 text-start ps-3 fw-bold'>Days</p>
                    </span>
                    <hr className={isMobileView ? 'd-none' : 'my-2'} />

                    {/* Render data dynamically for each category */}
                    {Object.entries(packageData).map(([category, categoryData]) => {
                        const isSelected = selectedPackages.some(pkg => pkg.category === category);

                        return (
                            <div key={category} className={`${isMobileView ? 'flex-column' : 'd-flex'} mt-4 HighlightselectedPkg ${isSelected ? 'selectedpkg_outline' : ''}`}>
                                <div className={` ${isMobileView ? 'pkg_categorysMob' : 'pkg_categorys'}  `}>
                                    <div className='d-flex align-items-center'>
                                        <img src={require(`../Images/${category}Icon.png`)} alt={`${category} logo`} style={{ height: '40px' }} className='ticker_mobn' loading="lazy"/>
                                        <p className='font ms-3 mb-0 align-center fontdesign-pkg'>
                                            {category.includes('Tags') ? 'Hot Deal Tags' : category.replace(/([a-z])([A-Z])/g, '$1 $2')}
                                        </p>
                                        <Tooltip
                                            key={category}
                                            title={
                                                <div>
                                                    <Typography style={{ fontSize: '12px' }}>
                                                        Get on average of {categoryData[0].price} impressions/{categoryData[0].days} days with a prime position on our homepage.{' '}
                                                        <a href='https://assignmentsold.ca/tools-to-promote-ad' style={{ cursor: 'pointer', textDecoration: 'underline' }} target='_blank' rel="noopener noreferrer">Learn more</a>
                                                    </Typography>
                                                </div>
                                            }
                                            arrow
                                            open={openTooltipIndex === category}
                                            onOpen={() => handleTooltipToggle(category)}
                                            onClose={() => handleTooltipToggle(category)}
                                        >
                                            <div className="info-icon" onClick={() => handleTooltipToggle(category)}>
                                                <InfoOutlinedIcon />
                                            </div>
                                        </Tooltip>
                                    </div>
                                </div>
                                <div className={` ${isMobileView ? 'pkg_categorysMob' : 'pkg_daysn'}   `}>

                                    <div className='d-flex align-center'>
                                        <p className='font ms-3 mb-0 fontdesign-pkg'>
                                            From <span className='fw-bold' style={{ color: '#4FB020' }}>${selectedOption[category].price}</span>
                                        </p>
                                    </div>
                                    <div className=' d-flex align-center'>
                                        <select
                                            className='font mb-0 fontdesign-pkg border-0 bg-transparent'
                                            aria-label='Default select example'
                                            value={selectedOption[category].days}
                                            onChange={(e) => handleDaysChange(e.target.value, category)}
                                            disabled={selectedPackages.some(pkg => pkg.category === category)}
                                        >
                                            {categoryData.map((item) => (
                                                <option key={item.days} value={item.days}>
                                                    {item.days} days
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='align-center'>
                                        <img src={handleAddorRemovepkgIcon(category)} alt='Add Package' className='Add_pkgIcon' onClick={() => handleAddToPackage(category)} loading="lazy"/>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    <div className='w-100 d-flex justify-content-end mt-4'>
                        <p className='fontdesign-pkg me-3 pkg_totaln'>Total Price: <span style={{ color: '#4FB020', fontWeight: '700' }}>  ${selectedPackages.reduce((total, pkg) => total + pkg.price, 0).toFixed(2)}</span></p>
                    </div>
                </div>
            </div>
            <div className='bg-white getquote_card_padding mob_pricing_carousel ms-3 ' style={{ maxWidth: "30%", borderRadius: "30px" }}>
                <Carousel
                    className="DrawerCarousel h-100"
                    prevIcon={customPrevIcon}
                    nextIcon={customNextIcon}
                    indicators={false}
                >
                    {carouselItems}
                </Carousel>
            </div>
        </div>
    );
};

export default ExtraPkg;









