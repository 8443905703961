import { createSlice } from "@reduxjs/toolkit";

const heartDataSlice = createSlice({
  name: "heartDataSlice",
  initialState: {
    favItems: [],
  },
  reducers: {
    setFavItems: (state, action) => {
      state.favItems = action.payload;
    },
    addFavItem: (state, action) => {
      const { id, uid } = action.payload;
      if (!state.favItems.some(item => item.id === id && item.uid === uid)) {
        state.favItems.push({ id, uid });
      }
    },
    removeFavItem: (state, action) => {
      const { id } = action.payload;
      state.favItems = state.favItems.filter(item => item.id !== id);
    },
}});

export const { setFavItems, addFavItem, removeFavItem } = heartDataSlice.actions;
export default heartDataSlice.reducer;
