import "../../../App.css";
import "@fontsource/montserrat";
import FavouriteCards from "./FavouriteCards";
export default function FavouriteHeader() {
  
  return (
    <div>
      <div>
        <div className="row mobile4remMarginTop">
          {/* <div className="col-lg-6 col-12">
            {" "}
            <h1 className="font pt-5 fav__assignments pb-4 px-3">
              <strong> Favourite Assignments</strong>
            </h1>
          </div> */}
          {/* <div className="col-lg-6 col-12">
            <div className="row">
              <div className="col-lg-3 p-0">
                {" "}
                <div>
                  {["right"].map((anchor) => (
                    <React.Fragment key={anchor}>
                      <Button
                        style={{
                          border: "1px solid #CCCCCC",
                          color: "black",
                          marginLeft: "20px",
                          marginTop: "11px",
                          padding: "7px",
                        }}
                        onClick={toggleDrawer(anchor, true)}
                      >
                        {" "}
                        {/* className="icoonfont"  
                        <BiFilter className="icoonfont" /> Filters
                      </Button>
                      <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                      >
                        {list(anchor)}
                      </Drawer>
                    </React.Fragment>
                  ))}
                </div>
              </div>
              <div className="col-lg-9 p-0 mt-2">
                <FavouriteSeachField />
              </div>
            </div>
          </div> */}
        </div>

        <div className="mobilevisibility font mt-3">
          <h3 className="fw-bold text-black ">Favorite Assignments</h3>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="cardsmapper d-flex flex-wrap">
              <FavouriteCards
              />
            </div>
          </div>
        </div>


      </div>
    </div>
  );
}
