import { NavLink } from "react-router-dom";
import Footer from "../Footer/Footer";

const ContactMessageRec = () => {
  return (
    <div className="contact_thanksMain">
            <div className="mb-3 mt-5 d-flex justify-content-center align-items-center">
              <NavLink to="/">
                <img
                  className="imgclass1"
                  src={require("../Images/icon.png")}
                  alt="AssignmentSold Logo"
                  loading="lazy"
                />
              </NavLink>
            </div>
      <div className="mt-4 mb-4">
      <h1 className="text-center fw-bold">Thanks For Contact Us</h1>
      <p className="text-center fw-bold">We will Reply you soon</p>
        </div>
        
        <div className="ContactRes_footer">
            <Footer />
        </div>
    </div>
  );
};

export default ContactMessageRec;
