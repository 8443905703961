import DashboardLayout from "../../Dashboard";
import './BuyerRequests.css'
import { useState, useEffect, useRef } from "react";
import Cookies from "universal-cookie";
import { IconButton, InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import BuyerRequestCard from "./BuyerRequestCard";
import axios from "axios";
import baseURL from "../../../config";
import { RotatingLines } from "react-loader-spinner";
import { toast, ToastContainer } from 'react-toastify';
import { useDispatch } from "react-redux";
import Drawer from "@mui/material/Drawer";
import DrawerContent from "../../../HomePage/SubComponents/MapandListing/DrawerContent";
import Loader from "../../../HomePage/SubComponents/MapandListing/Loader";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { removePdfData } from "../../../ReduxStore/Slices/PdfDownloadSlice";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Dialog from '@mui/material/Dialog';
import dayjs from 'dayjs';
import { Helmet } from 'react-helmet';

const BuyerRequests = () => {

    const cookies = new Cookies();
    const jwt_token = cookies.get("jwt_authorization");
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const typingTimeout = useRef();
    const [loader, setLoader] = useState(false);
    const [dltApiCall, setDltApiCall] = useState(false);
    const [activeDateBtn, setActiveDateBtn] = useState('Today');
    const [currentSearchQuery, setCurrentSearchQuery] = useState()
    const [data, setData] = useState([]);
    const [startDate, setStartDate] = useState(dayjs());
    const [endDate, setEndDate] = useState(null);
    const [status, setStatus] = useState('New');
    const [searchBy, setSearchBy] = useState('ProjectName');
    const [searchValue, setSearchValue] = useState('');
    const [openDrawer, setOpenDrawer] = useState(null);
    const [openCalendar, setOpenCalendar] = useState(false);

    const formatDate = (date) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const formattedDate = new Date(date).toLocaleDateString('en-CA', options); // 'en-CA' ensures the format is YYYY-MM-DD
        return formattedDate;
    };

    const handleTodayClick = () => {
        const currentDate = dayjs();
        setStartDate(currentDate);
        setEndDate(null)
        setActiveDateBtn('Today');
    }

    const handleYesterdayClick = () => {
        const yesterday = dayjs().subtract(1, 'day');
        setStartDate(yesterday);
        setActiveDateBtn('Yesterday');
        setEndDate(null)
    }

    const fetchData = async () => {
        setLoader(true)
        const formattedStartDate = formatDate(startDate);
        let formattedEndDate;

        if (endDate === null) {
            formattedEndDate = endDate;
        } else {
            formattedEndDate = formatDate(endDate);
        }

        const data = {
            Start: formattedStartDate,
            End: formattedEndDate,
            Status: status,
            SearchBy: searchBy,
            Searchvalue: searchValue
        };

        const jsonData = JSON.stringify(data);
        const headers = {
            Authorization: `Bearer ${jwt_token}`,
            'Content-Type': 'application/json',
        };

        try {
            const response = await axios.post(
                `${baseURL}/api/Admin/GetBuyerRequests`,
                jsonData,
                { headers },
            );
            if (response?.data?.result === 1) {
                setLoader(false)
                setData(response?.data?.data)
            } else {
                setLoader(false)
                toast.error('Data Not Successfully Fetch')
            }
        } catch (error) {
            toast.error(error);
            setLoader(false)
        }
    };

    const handleRequestStatusChange = (uId, status) => {
        const headers = {
            Authorization: `Bearer ${jwt_token}`,
        };
        axios.put(`${baseURL}/api/Admin/UpdateBuyerRequest?UId=${uId}&status=${status}`, null, { headers })
            .then(response => {
                if (response?.data?.result === 1) {
                    setLoader(true)
                    fetchData()
                } else {
                    toast.error("Try Again")
                }
            })
            .catch(error => {
                toast.error(error)
            });
    }

    const handleDeleteRequest = (uId) => {
        setDltApiCall(true)
        const headers = {
            Authorization: `Bearer ${jwt_token}`,
        };
        axios.delete(`${baseURL}/api/Admin/DeleteBuyerRequest/${uId}`, { headers })
            .then(response => {
                if (response?.data?.result === 1) {
                    const updatedData = data?.filter((e) => e.uId !== uId);
                    setData(updatedData)
                    setDltApiCall(false)
                    toast.success("Successfully Deleted")
                } else {
                    toast.error("Try Again")
                    setDltApiCall(false)
                }
            })
            .catch(error => {
                setDltApiCall(false)
                toast.error(error)
            });
    }

    useEffect(() => {
        if (!jwt_token) {
            navigate('/login');
            return;
        }
        fetchData()
        // eslint-disable-next-line
    }, [startDate, status, currentSearchQuery, searchBy, endDate]);

    const handleSearchInputChange = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchValue(query);
        if (typingTimeout.current) {
            clearTimeout(typingTimeout.current);
        }

        typingTimeout.current = setTimeout(async () => {
            setCurrentSearchQuery(query);
        }, 1000);
    };

    const [drawerLoading, setDrawerLoading] = useState(false);

    //Drawer open even refresh
    const slug = useParams();
    // eslint-disable-next-line
    const path = location.pathname;
    const slugPresent = slug.id;
    const [draweritem, setDrawerItem] = useState();
    const [itemID, setItemID] = useState(1);
    const { searchData } = location.state || {};
    useEffect(() => {
        if (searchData === undefined) {
            if (slugPresent) {
                setDrawerLoading(true)
                axios
                    .get(`${baseURL}/api/Assignment/GetAssignment/${slugPresent}`)
                    .then((response) => {
                        if (response?.data?.data) {
                            setDrawerItem(response?.data?.data);
                            setOpenDrawer(response?.data?.data?.id);
                            setItemID(response?.data?.data?.id);
                            setDrawerLoading(false)
                        } else {
                            toast.error("There is no Data for this Assignment");
                            navigate("/dashboard/buyer-request");
                            setDrawerLoading(false)
                        }
                    })
                    .catch((error) => {
                        navigate("/dashboard/buyer-request");
                        toast.error('Error in fetching Listing');
                        setDrawerLoading(false)
                    });
            }
        }
        // eslint-disable-next-line
    }, [slugPresent]);


    useEffect(() => {
        const handlePopstate = () => {
            const currentPath = window.location.pathname;
            if (currentPath === "/dashboard/buyer-request") {
                setOpenDrawer(null);
                setItemID(1);
                dispatch(removePdfData());
            }
        };

        window.addEventListener("popstate", handlePopstate);

        return () => {
            window.removeEventListener("popstate", handlePopstate);
        };
    }, [dispatch]);

    const isDrawerOpen = (itemId) => {
        // eslint-disable-next-line
        return openDrawer == itemId;
    };

    const toggleDrawer = (anchor, itemId) => () => {
        setOpenDrawer(null);
        setItemID(1);
        dispatch(removePdfData());
        navigate("/dashboard/buyer-request");
    };


    const handleButtonClick = () => {
        setActiveDateBtn('DateRange')
        setOpenCalendar(true);
    };

    const handleClose = () => {
        setOpenCalendar(false);
    };

    const handleStartDateRangeChange = (date) => {
        setStartDate(dayjs(date));
    };

    const handleEndDateRangeChange = (date) => {
        setEndDate(dayjs(date));
    };

    return (
        <DashboardLayout>

            <Helmet>
                <title>AssignmentSold.ca</title>
            </Helmet>

            <ToastContainer />
            <div className="row br-mobile4remMarginTop"></div>
            <section className='page-content br-page-content'>
                <div className="br-container mb-3">
                    <div className="headingtitlelister">
                        <h3 className="MessagesFonts m-0" style={{ color: "black" }}>
                            Buyer Request
                        </h3>
                    </div>
                    <div className="br-filterconntainer">
                        <div className="br-datebtn">
                            <button className={activeDateBtn === 'Today' ? 'Activebr-StatusBtn' : 'br_statusBtn'} onClick={handleTodayClick}>
                                Today
                            </button>
                            <button className={activeDateBtn === 'Yesterday' ? 'Activebr-StatusBtn' : 'br_statusBtn'} onClick={handleYesterdayClick}>
                                Yesterday
                            </button>

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <button className={activeDateBtn === 'DateRange' ? 'Activebr-StatusBtn' : 'br_statusBtn'} onClick={handleButtonClick}>
                                    Date range
                                </button>
                                <Dialog open={openCalendar} onClose={handleClose}>
                                    <DatePicker value={startDate} onChange={handleStartDateRangeChange} />
                                    <DatePicker value={endDate} onChange={handleEndDateRangeChange} />
                                </Dialog>
                            </LocalizationProvider>
                        </div>
                        <div className="d-flex br-searchInputAndTitle">
                            <form
                                onSubmit={(e) => e.preventDefault()}
                                className="border d-flex end_round2 br_serachInp w-100"
                            >
                                <IconButton
                                    type="button"
                                    sx={{ p: "10px" }}
                                    aria-label="search"
                                >
                                    <SearchIcon />
                                </IconButton>
                                <InputBase
                                    size="medium"
                                    sx={{ flex: 1, paddingLeft: 1 }}
                                    placeholder={"Search by title,id and price"}
                                    value={searchValue}
                                    onChange={handleSearchInputChange}
                                />
                            </form>
                            <select
                                className="form-select TitleMenuWidth end_round m-0 br_serachSelect"
                                aria-label="Default select example"
                                value={searchBy}
                                onChange={(e) => setSearchBy(e.target.value)}
                            >

                                <option value="ListingId">ListingId</option>
                                <option value="ProjectName">Project Name</option>
                                <option value="Price">Price</option>
                                <option value="BuyerName">Buyer Name</option>
                                <option value="BuyerEmail">Buyer Email</option>
                                <option value="BuyerNumber">Buyer Number</option>
                            </select>
                        </div>
                    </div>
                </div>

                {/* ======== Status Filter Section ======== */}

                <div className="br-Statusbuttons">
                    <button className={`btn ${status === 'New' ? 'br_ActivestatusBtn' : 'br_statusBtn'}`} onClick={() => setStatus('New')}>
                        New
                    </button>
                    <button className={`btn ${status === 'Contacted' ? 'br_ActivestatusBtn' : 'br_statusBtn'}`} onClick={() => setStatus('Contacted')}>
                        Contacted
                    </button>
                    <button className={`btn ${status === 'Completed' ? 'br_ActivestatusBtn' : 'br_statusBtn'}`} onClick={() => setStatus('Completed')}>
                        Completed
                    </button>
                    <button className={`btn ${status === 'DND' ? 'br_ActivestatusBtn' : 'br_statusBtn'}`} onClick={() => setStatus('DND')}>
                        DND
                    </button>
                </div>

                {/* ======== Card Section ======== */}
                {loader ?
                    <center className="text-center mt-4">
                        <RotatingLines
                            strokeColor="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="70"
                            visible={loader}
                        />
                    </center>
                    :
                    data.length === 0 ?
                        <>
                            <div className="no__FoundMain font">
                                <div className="no__Found__img">
                                    <img className='not_found_img_mobile' src={require("../../../images/noResultFound.png")} alt="Result not found" loading="lazy"/>
                                </div>
                                <div className="no__FoundText font-family-regular not_found_Text_mobile">
                                    <p>No RecordFound</p>
                                </div>

                            </div>
                        </>
                        :
                        <div className="br-CardContainer">
                            {data?.map(item => (
                                <BuyerRequestCard key={item.id} handleDeleteRequest={handleDeleteRequest} handleRequestStatusChange={handleRequestStatusChange} dltApiCall={dltApiCall} data={item} />
                            ))}
                        </div>
                }
            </section>
            {drawerLoading ? (
                <div className="scrollLoader fav_scrollLoader">
                    <Loader size="80" />
                    <p style={{ color: "#F8A23E", fontWeight: 600 }}>Loading....</p>
                </div>
            ) : (
                <Drawer
                    anchor="right"
                    open={isDrawerOpen(itemID)}
                    onClose={toggleDrawer("right", itemID)}
                >
                    <DrawerContent
                        item={draweritem}
                        anchor={"right"}
                        toggleDrawer={toggleDrawer}
                    />
                </Drawer>
            )}
        </DashboardLayout>
    )
}

export default BuyerRequests;