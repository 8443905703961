import React from "react";
import FavouriteHeader from "./SubComponents/FavouriteHeader";
import DashboardLayout from "../../Dashboard/Dashboard";

export default function FavouritePage() {
  // handle card heart

  return (
    <DashboardLayout>
      <div className='page-content '>
        <FavouriteHeader />
      </div>
    </DashboardLayout>
  );
}
