import React, { useEffect, useState, useRef, useCallback } from "react";
import "./GoogleMaps.css";
import mapboxgl from "mapbox-gl";
import { Card } from "react-bootstrap";
import PopUpCard from "./PopUpCard";
import { ThreeDots } from 'react-loader-spinner'
// eslint-disable-next-line
import DevelopmentToken from "../../../MapBoxToken";
// eslint-disable-next-line
// import ProductionToken from "../../../ProductionToken";
// import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// eslint-disable-next-line
import axios from "axios";
import baseURL from "../../../config";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { createRoot } from "react-dom/client";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
mapboxgl.accessToken = `${DevelopmentToken}`;

function MapboxComponent({ setDrawerLoading, centerPopUP, hoveredSlug, toggleIcon, insidePolygon, setInsidePolygon, setScrollPage, categoryLocation, mapRef, isLoading, resetTrigger }) {

  const navigate = useNavigate();
  const [, setOpen] = React.useState(false);
  const [popupLoading, setPopupLoading] = useState(false);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [showDrawBtn, setShowDrawBtn] = useState(true);
  const [showClearBtn, setShowClearBtn] = useState(false);
  // const mapRef = useRef(null);
  const drawRef = useRef(null);
  const centerPopUPRef = useRef(centerPopUP);

  // Data Recieving from the redux Store
  const MapboxAllData = useSelector((state) => state.MapBoxDataSlice);

  const handleLinkClick = (slug) => {
    navigate(`/assignment-sale/${slug}`);
  };

  // eslint-disable-next-line
  const handleOpen = () => {
    setOpen(true);
  };

  // eslint-disable-next-line
  const handleClose = () => {
    setOpen(false);
  };

  // eslint-disable-next-line
  const [activeMarker, setActiveMarker] = useState(null);
  useEffect(() => { }, [handleClose, handleOpen]);

  const handleDrawPolygon = () => {
    drawRef.current.changeMode('draw_polygon');
  };

  const handleDeletePolygon = () => {
    drawRef.current.deleteAll();
    setShowClearBtn(false);
    setShowDrawBtn(true);
    setInsidePolygon('')
  };

  const updateArea = useCallback(() => {
    const data = drawRef.current.getAll();
    const drawFeatures = data?.features[0]?.geometry;
    if (drawFeatures?.coordinates[0]) {
      const coordinates = drawFeatures.coordinates[0];
      setInsidePolygon(`${coordinates[0][0]} ${coordinates[0][1]}, ${coordinates[3][0]} ${coordinates[3][1]}, ${coordinates[2][0]} ${coordinates[2][1]}, ${coordinates[1][0]} ${coordinates[1][1]}, ${coordinates[0][0]} ${coordinates[0][1]}`)
      setScrollPage(1)
    }
    setShowDrawBtn(false);
    setShowClearBtn(true);
  }, [setInsidePolygon, setShowDrawBtn, setShowClearBtn, setScrollPage]);

  useEffect(() => {
    centerPopUPRef.current = centerPopUP;
  }, [centerPopUP]);


  useEffect(() => {
    const map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/mapbox/streets-v11",
      center: [-79.27525, 43.830992],
      zoom: 9,
    });

    mapRef.current = map;

    const draw = new MapboxDraw({
      displayControlsDefault: false,
      controls: {},
      styles: [
        {
          id: "gl-draw-polygon-fill",
          type: "fill",
          filter: ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
          paint: {
            "fill-color": "#02549E",
            "fill-outline-color": "#02549E",
            "fill-opacity": 0.1,
          },
        },
        {
          id: "gl-draw-polygon-stroke-active",
          type: "line",
          filter: ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
          layout: {
            "line-cap": "round",
            "line-join": "round",
          },
          paint: {
            "line-color": "#02549E",
            "line-width": 2,
          },
        },
        {
          id: "gl-draw-line-active",
          type: "line",
          filter: ["all", ["==", "$type", "LineString"], ["!=", "mode", "static"]],
          layout: {
            "line-cap": "round",
            "line-join": "round",
          },
          paint: {
            "line-color": "#02549E",
            "line-width": 2,
          },
        },
        {
          id: "gl-draw-polygon-and-line-inactive",
          type: "line",
          filter: ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
          layout: {
            "line-cap": "round",
            "line-join": "round",
          },
          paint: {
            "line-color": "#02549E",
            "line-width": 2,
          },
        },
        {
          id: "gl-draw-line-inactive",
          type: "line",
          filter: ["all", ["==", "$type", "LineString"], ["==", "mode", "static"]],
          layout: {
            "line-cap": "round",
            "line-join": "round",
          },
          paint: {
            "line-color": "#02549E",
            "line-width": 2,
          },
        },
      ],
    });

    map.on("draw.create", updateArea);
    map.on("draw.update", updateArea);
    map.on("draw.delete", updateArea);

    map.on("load", () => {
      map.addControl(draw);
      drawRef.current = draw;
      setMapLoaded(true);
    });

    return () => map.remove();
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    if (!mapRef.current || !mapLoaded) return;
    const map = mapRef.current;
    if (map.getLayer("clusters")) {
      map.removeLayer("clusters");
      map.removeLayer("cluster-count");
      map.removeLayer("unclustered-point");
      map.removeSource("markers");
    }

    const markerData =
      MapboxAllData[0]?.length !== undefined ? MapboxAllData[0] : null;
    if (markerData && markerData.length > 0) {
      const bounds = new mapboxgl.LngLatBounds();
      markerData.forEach((item) => {
        bounds.extend([item.longitude, item.latitude]);
      });

      if (!insidePolygon) {
        if (markerData.length === 1) {
          map.easeTo({
            center: bounds.getCenter(),
            zoom: 14,
            duration: 2000,
          });
        } else {
          map.fitBounds(bounds, {
            padding: 40,
            duration: 2000,
          });
        }
      }

      map.addSource("markers", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: markerData.map((item) => ({
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [item.longitude, item.latitude],
            },
            properties: item,
          })),
        },
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: 50,
      });
    }


    map.addLayer({
      id: "clusters",
      type: "circle",
      source: "markers",
      filter: ["has", "point_count"],
      paint: {
        "circle-color": [
          "step",
          ["get", "point_count"],
          "#51bbd6",
          100,
          "#51bbd6",
          750,
          "#51bbd6",
        ],
        "circle-radius": [
          "step",
          ["get", "point_count"],
          20,
          100,
          30,
          750,
          40,
        ],
      },
    });

    map.addLayer({
      id: "cluster-count",
      type: "symbol",
      source: "markers",
      filter: ["has", "point_count"],
      layout: {
        "text-field": ["get", "point_count_abbreviated"],
        "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
        "text-size": 12,
      },
    });

    map.addLayer({
      id: "unclustered-point",
      type: "circle",
      source: "markers",
      filter: ["!", ["has", "point_count"]],
      paint: {
        "circle-color": "#11b4da",
        "circle-radius": 8,
        "circle-stroke-width": 1,
        "circle-stroke-color": "#fff",
      },
    });

    // map.on("click", "clusters", (e) => {
    const handleClusterClick = (e) => {
      const features = map.queryRenderedFeatures(e.point, {
        layers: ["clusters"],
      });

      const clusterId = features[0].properties.cluster_id;

      map
        .getSource("markers")
        .getClusterLeaves(clusterId, 100, 0, async (err, leaves) => {
          if (err) throw err;

          const clusterCoordinates = features[0].geometry.coordinates;

          // Zoom more if there are more than 10 properties
          if (leaves.length > 10) {
            map.easeTo({
              center: clusterCoordinates,
              zoom: map.getZoom() + 2,
            });
          } else {
            setPopupLoading(true);
            const requests = leaves.map((leaf) => {
              return axios.get(
                `${baseURL}/api/Assignment/GetAssignment/${leaf.properties.slug}`
              );
            });

            try {
              const responses = await Promise.all(requests);

              // Concatenate information for all properties in the cluster
              const propertiesData = responses.map((response) => {
                return response?.data?.result === 1
                  ? response.data.data
                  : null;
              });

              const validProperties = propertiesData.filter(Boolean);

              // Create a popup with content for all properties in the cluster
              if (validProperties.length > 0) {
                setPopupLoading(false);
                const popupContent = (
                  <>
                    <div className="MapListing_count">
                      <p>{`${validProperties.length} Listings`}</p>
                    </div>
                    <div className="MapListingScroll_cards">
                      {validProperties.map((properties) => (
                        <div
                          key={properties.slug}
                          className="font mymapcareds mapbox_popCard"
                          style={{ marginBottom: "10px" }}
                        >
                          <Card
                            className="p-0"
                            sx={{
                              maxWidth: 301,
                              borderRadius: " 8px  8px 0px  0px ",
                              position: "relative",
                              padding: "0px !important",
                            }}
                            onClick={() => {
                              handleLinkClick(properties.slug);
                              setDrawerLoading(true);
                            }}
                          >
                            <PopUpCard record={properties} />
                          </Card>
                        </div>
                      ))}
                    </div>
                  </>
                );

                const container = document.createElement("div");
                const root = createRoot(container);
                root.render(popupContent);

                if (centerPopUPRef?.current) {
                  // Set up a popup with the specified content
                  const popup = new mapboxgl.Popup({
                    anchor: 'bottom',
                    maxWidth: 301,
                    maxHeight: 600
                  })
                    .setLngLat(clusterCoordinates)
                    .setDOMContent(container);
                  const offsetX = centerPopUPRef?.current ? 0 : 0;
                  const offsetY = centerPopUPRef?.current ? 210 : 0;
                  const originalCenter = map.getCenter();
                  map.easeTo({
                    center: clusterCoordinates,
                    offset: [offsetX, offsetY],
                    zoom: map.getZoom(),
                    duration: 1000,
                  });
                  popup.on('close', () => {
                    map.easeTo({
                      center: originalCenter,
                      duration: 1000,
                    });
                  });
                  // Open the popup
                  popup.addTo(map);
                }
                else {
                  // Set up a popup with the specified content
                  const popup = new mapboxgl.Popup({
                    maxWidth: 301,
                    maxHeight: 600
                  })
                    .setLngLat(clusterCoordinates)
                    .setDOMContent(container);
                  // Open the popup
                  popup.addTo(map);
                  map.easeTo({
                    padding: 40,
                    zoom: map.getZoom(),
                  });
                }
              }
            } catch (error) {
              console.error("There is problem to fetch Data");
              setPopupLoading(false);
            }
          }
        });
      // });
    }

    map.on("click", "clusters", handleClusterClick);

    // map.on("click", "unclustered-point", async (e) => {
    const handleUnClusterClick = async (e) => {
      const properties = e.features[0].properties;
      setActiveMarker(properties);
      const coordinates = e.features[0].geometry.coordinates.slice();
      setPopupLoading(true);
      try {
        const response = await axios.get(
          `${baseURL}/api/Assignment/GetAssignment/${properties.slug}`
        );
        if (response?.data?.result === 1) {
          setPopupLoading(false);
          const cardContent = (
            <>
              <div className="font mymapcareds mapbox_popCard">
                <Card
                  className="p-0"
                  sx={{
                    maxWidth: 301,
                    borderRadius: " 8px  8px 0px  0px ",
                    position: "relative",
                    padding: "0px !important",
                  }}
                  onClick={() => {
                    handleLinkClick(response?.data?.data?.slug);
                    setDrawerLoading(true);
                  }}
                >
                  <PopUpCard record={response?.data?.data} />
                </Card>
              </div>
            </>
          );

          const container = document.createElement("div");
          const root = createRoot(container);
          root.render(cardContent);

          if (centerPopUPRef?.current) {
            // Set up a popup with the specified content
            const popup = new mapboxgl.Popup({
              anchor: 'bottom',
              maxWidth: 301,
              maxHeight: 600
            })
              .setLngLat(coordinates)
              .setDOMContent(container);
            const offsetX = centerPopUPRef?.current ? 0 : 0;
            const offsetY = centerPopUPRef?.current ? 150 : 0;
            const originalCenter = map.getCenter();
            map.easeTo({
              center: coordinates,
              offset: [offsetX, offsetY],
              zoom: map.getZoom(),
              duration: 1000,
            });
            popup.on('close', () => {
              map.easeTo({
                center: originalCenter,
                duration: 1000,
              });
            });
            // Open the popup
            popup.addTo(map);
          }
          else {
            // Set up a popup with the specified content
            const popup = new mapboxgl.Popup({
              maxWidth: 301,
              maxHeight: 600
            })
              .setLngLat(coordinates)
              .setDOMContent(container);
            // Open the popup
            popup.addTo(map);
            map.easeTo({
              padding: 40,
              zoom: map.getZoom(),
            });
          }
        } else {
          toast.error("There is no Data for this location");
          setPopupLoading(false);
        }
      } catch (error) {
        console.error("There is problem to fetch Data");
        setPopupLoading(false);
      }
    }
    // });

    map.on("click", "unclustered-point", handleUnClusterClick)

    map.on("mouseenter", "clusters", () => {
      map.getCanvas().style.cursor = "pointer";
    });

    map.on("mouseleave", "clusters", () => {
      map.getCanvas().style.cursor = "";
      setActiveMarker("");
    });

    return () => {
      map.off("click", "clusters", handleClusterClick);
      map.off("click", "unclustered-point", handleUnClusterClick);
    };

    // eslint-disable-next-line
  }, [MapboxAllData, mapLoaded]);

  useEffect(() => {
    const map = mapRef.current;

    if (hoveredSlug) {
      const updateColors = () => {
        const source = map.getSource("markers");
        const features = map.queryRenderedFeatures({
          layers: ["clusters", "unclustered-point"],
        });
        features.forEach((feature) => {
          const clusterId = feature.properties.cluster_id;

          if (feature.layer.id === "clusters") {
            source.getClusterLeaves(clusterId, 5000, 0, (err, leaves) => {
              if (err) return;

              const containsHoveredSlug = leaves.some(
                (leaf) => leaf.properties.slug === hoveredSlug
              );

              if (containsHoveredSlug) {
                map.setPaintProperty("clusters", "circle-color", [
                  "case",
                  ["==", ["get", "cluster_id"], clusterId],
                  "#f6a241", // Color for the hovered cluster
                  "#51bbd6"  // Default color for other clusters
                ]);
              }
            });
          } else if (feature.layer.id === "unclustered-point" && feature.properties.slug === hoveredSlug) {
            map.setPaintProperty("unclustered-point", "circle-color", [
              "case",
              ["==", ["get", "slug"], hoveredSlug],
              "#f6a241",
              "#51bbd6",
            ]);
          }
        });
      };

      if (map.isStyleLoaded()) {
        updateColors();
      } else {
        map.once("styledata", updateColors());
      }
    } else {
      if (map.isStyleLoaded()) {
        map.setPaintProperty("clusters", "circle-color", "#51bbd6");
        map.setPaintProperty("unclustered-point", "circle-color", "#51bbd6");
      } else {
        map.once("styledata", () => {
          map.setPaintProperty("clusters", "circle-color", "#51bbd6");
          map.setPaintProperty("unclustered-point", "circle-color", "#51bbd6");
        });
      }
    }
    // eslint-disable-next-line
  }, [hoveredSlug]);


  useEffect(() => {
    if (!toggleIcon || categoryLocation || resetTrigger) {
      drawRef?.current?.deleteAll();
      setShowClearBtn(false);
      setShowDrawBtn(true);
      setInsidePolygon('')
    }
  }, [toggleIcon, setInsidePolygon, categoryLocation, resetTrigger])

  return (
    <div id="map" className="googleapiss">

      {(showDrawBtn && mapLoaded) && (
        <button
          className="map_drawDel_Btn map_draw_Btn"
          onClick={handleDrawPolygon}>Draw</button>
      )}

      {(showClearBtn && mapLoaded) && (
        <button
          className="map_drawDel_Btn map_deleteBtn"
          onClick={handleDeletePolygon}>Clear map Bounds</button>
      )}

      {(isLoading && mapLoaded) && (
        <div
          style={{ zIndex: 110, position: "absolute", backgroundColor: 'rgba(254, 254, 254, 0.3)', width: '100%', height: '100%' }}
        >
          <div style={{ zIndex: 120, backgroundColor: '#ffff', borderRadius: '5px', margin: '20px auto 0 auto', width: 'fit-content', padding: '0px 18px' }}>
            <ThreeDots
              visible={true}
              height="40"
              width="40"
              color="#F6941C"
              radius="6"
              ariaLabel="three-dots-loading"
            />
          </div>
        </div>
      )}

      {/* {renderInfoWindowContent(activeMarker)} */}
      {popupLoading && (
        <span
          style={{ zIndex: 100, position: "absolute", bottom: 0 }}
          className="map_lineLoader"
        ></span>
      )}
    </div>
  );
}

export default MapboxComponent;
