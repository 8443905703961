import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import { useSelector } from "react-redux";

export default function Marquees() {
  const data = useSelector((state) => state.adminSiteDataSlice);
  const webHighlight = data[0]?.highlight;
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 800);

  useEffect(() => {
    function handleResize() {
      setIsMobileView(window.innerWidth <= 800);
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className={isMobileView ? "pb-2" : "pb-4"}>
      {" "}
      {webHighlight && (
        <Marquee direction="left" className="marqmarquez">
          {webHighlight}
        </Marquee>
      )}
    </div>
  );
}
